import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Loader } from "../global/Loader";
import {
  BsPersonFillGear,
  BsCreditCardFill,
  BsFillChatHeartFill,
} from "react-icons/bs";
import { MdAlternateEmail, MdSell, MdSupport } from "react-icons/md";
import { BiKey, BiUserPin } from "react-icons/bi";
import { RiKeyFill } from "react-icons/ri";
import { IoInformationCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const {
    signOut,
    activeUserProfile,
    currentUser,
    setActiveUser,
    updateColorPreference,
    colorMode,
  } = useAuth();

  const navigate = useNavigate();
  let colorModeToggle;

  const [isDesktop, setIsDesktop] = useState(true);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
  };

  window.addEventListener("resize", updateWidth);

  useEffect(() => {
    updateWidth();
    setActiveUser(currentUser.uid);
    colorModeToggle = document.getElementById("color-mode-toggle");
    if (colorModeToggle) {
      colorModeToggle.addEventListener("sl-change", (event) => {
        updateColorPreference(currentUser.uid, event.target.value);
      });
    }
  }, [colorModeToggle]);

  const signOutUser = () => {
    signOut().then(() => {
      if (!currentUser) {
        navigate("/sign-in");
      }
    });
  };

  return (
    <div className="profile-dashboard">
      <div className="title">
        <h1>Profile</h1>
        {isDesktop ? (
          <sl-button id="desktop-sign-out" onClick={signOutUser}>
            Sign Out
          </sl-button>
        ) : null}
      </div>

      <section className="header">
        <div>
          <h2>Hi, {activeUserProfile?.firstName}!</h2>
          <h3>Shoeboxing since 2022</h3>
        </div>
        <div>
          <img src={activeUserProfile?.picture} />
        </div>
      </section>

      <section className="general">
        <small>General Information</small>
        <div className="button-row">
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/profile/personal-info")}
            variant="neutral"
          >
            <BsPersonFillGear />
            <span>Edit Personal Info</span>
          </sl-button>
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/profile/edit-picture")}
            variant="neutral"
          >
            <BiUserPin />
            <span>Edit Profile Picture</span>
          </sl-button>
        </div>
      </section>

      <section className="account">
        <small>Account Controls</small>
        <div className="button-row">
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/profile/subscription")}
            variant="neutral"
          >
            <MdSell />
            <span>View Subscription</span>
          </sl-button>
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/profile/billing")}
            variant="neutral"
          >
            <BsCreditCardFill />
            <span>Billing & Invoices</span>
          </sl-button>
        </div>
        <div className="button-row">
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/profile/update-email")}
            variant="neutral"
          >
            <MdAlternateEmail />
            <span>Update Email</span>
          </sl-button>
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/profile/update-password")}
            variant="neutral"
          >
            <RiKeyFill />
            <span>Update Password</span>
          </sl-button>
        </div>
      </section>

      <section className="preferences">
        <small>Preferences</small>
        <sl-radio-group
          id="color-mode-toggle"
          label="Color Mode"
          value={colorMode}
        >
          <sl-radio-button value="light">Light</sl-radio-button>
          <sl-radio-button value="dark">Dark</sl-radio-button>
          <sl-radio-button value="system">System</sl-radio-button>
        </sl-radio-group>
      </section>

      <section className="support">
        <small>App Information & Support</small>
        <div className="tri-button-row">
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/support")}
            variant="neutral"
          >
            <MdSupport />
            <span>Support</span>
          </sl-button>
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/feedback")}
            variant="neutral"
          >
            <BsFillChatHeartFill />
            <span>Feedback</span>
          </sl-button>
          <sl-button
            id="blocks-icon-button"
            onClick={() => navigate("/about")}
            variant="neutral"
          >
            <IoInformationCircle />
            <span>About</span>
          </sl-button>
        </div>
      </section>
      {isDesktop ? null : <sl-button onClick={signOutUser}>Sign Out</sl-button>}
    </div>
  );
};

export { Profile };
