import React from 'react'
import { Link } from 'react-router-dom';
import { TbArrowNarrowRight } from 'react-icons/tb';
import { useAuth } from '../../contexts/AuthContext';

const NotFound = () => {

  const { currentUser } = useAuth();

  return (
    <div className='not-found-page'>
      <h1 id='fourohfour-header'>404</h1>
      <span id='fourohfour-help-text'>Hmm, nothing is here. Try again or {currentUser ? <Link to='/expenses'>go to expenses <TbArrowNarrowRight /></Link> : <Link to='/'>go home <TbArrowNarrowRight /></Link>}</span>
    </div>
  )
}

export { NotFound };