import React from 'react';

const Tiles = () => {
  return (
    <section>
      
    </section>
  )
}

export {Tiles}