import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Loader } from "../global/Loader";
import { AiFillPlusCircle } from "react-icons/ai";
import { TbListDetails, TbDiscountCheckFilled } from "react-icons/tb";
import { TiTimes } from "react-icons/ti";
import { ExpensesList } from "./ExpensesList";
import { ExpenseForm } from "./ExpenseForm";
import { dateHandler } from "../../hooks-n-utils/date";
import { currencyHandler } from "../../hooks-n-utils/currency";
import { titleHandler } from "../../hooks-n-utils/titling";

const Expenses = () => {
  const { expenses, loadingExpenses, noExpenses } = useAuth();

  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState();

  let addDrawer;
  let editDrawer;
  let openAdd;
  let openEdit;
  let closeAdd;
  let closeEdit;

  const [isDesktop, setIsDesktop] = useState(true);
  const [isTightMobile, setIsTightMobile] = useState(true);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
    setIsTightMobile(window.innerWidth < 420);
    addDrawer = document.getElementById("add-expense-drawer");
    editDrawer = document.getElementById("edit-expense-drawer");
  };

  window.addEventListener("resize", updateWidth);

  useEffect(() => {
    openAdd = document.getElementById("open-add-btn");
    openEdit = document.getElementById("open-edit-btn");
    closeAdd = document.getElementById("close-add-btn");
    closeEdit = document.getElementById("close-edit-btn");
    openAdd?.addEventListener("click", () => addDrawer.show());
    openEdit?.addEventListener("click", () => editDrawer.show());
    closeAdd?.addEventListener("click", () => addDrawer.hide());
    closeEdit?.addEventListener("click", () => editDrawer.hide());

    // Prevent the dialog from closing when the user clicks on the overlay
    addDrawer?.addEventListener("sl-request-close", (event) => {
      if (event.detail.source === "overlay") {
        event.preventDefault();
      }
    });

    // Prevent the dialog from closing when the user clicks on the overlay
    editDrawer?.addEventListener("sl-request-close", (event) => {
      if (event.detail.source === "overlay") {
        event.preventDefault();
      }
    });

    updateWidth();

    if (isFormValid) {
      document.querySelector(".shoebox-drawer-action-button").disabled = false;
    } else {
      document.querySelector(".shoebox-drawer-action-button").disabled = true;
    }

    return () => {
      openAdd?.removeEventListener("click", () => {});
      openEdit?.removeEventListener("click", () => {});
      closeAdd?.removeEventListener("click", () => {});
      closeEdit?.removeEventListener("click", () => {});
      addDrawer?.removeEventListener("sl-request-close", () => {})
      window.removeEventListener("resize", () => {});
      editDrawer?.removeEventListener("sl-request-close", () => {});
    }
  }, [
    openAdd,
    openEdit,
    closeAdd,
    closeEdit,
    addDrawer,
    editDrawer,
    isFormValid,
    expenses,
    loadingExpenses,
    noExpenses,
    selectedExpense,
  ]);

  const LoadingExpenses = () => {
    return (
      <div className="expenses-loader">
        <Loader />
      </div>
    );
  };

  const NoExpenses = () => {
    return (
      <div className="empty-expenses">
        <TbListDetails />
        <h2>No Expenses...Yet</h2>
        <span>Get Started:</span>
        <sl-button
          class="blocks-pill-btn"
          onClick={() => addDrawer?.show()}
          pill
        >
          Add Your First Expense
        </sl-button>
      </div>
    );
  };

  const RenderTags = () => {
    return (
      <div className="tags-container">
        {
          selectedExpense.tags.map((tag, index) => {
            return (
              <sl-badge key={index}>{tag}</sl-badge>
            )
          })
        }
      </div>
    )
  }

  const ExpenseDetail = () => {
    return (
      <section className="expense-detail">
        <div className="detail-header">
          <div>
            <h3>{selectedExpense?.name}</h3>
            <span>{dateHandler(selectedExpense?.date)}</span>
          </div>
          <div className="detail-card-actions">
            <sl-button
              class="blocks-pill-btn"
              pill
              onClick={() => editDrawer?.show()}
            >
              Edit
            </sl-button>
            <AiFillPlusCircle
              className="close-detail"
              onClick={() => setSelectedExpense()}
            />
          </div>
        </div>
        <div className="detail-body">
          <small>Amount</small>
          <h4 className="currency">
            {currencyHandler(selectedExpense?.amount)}
          </h4>

          <small>Category</small>
          <sl-badge variant="neutral">
            {titleHandler(selectedExpense?.category)}
          </sl-badge>

          <small>Eligibility</small>
          <h4 className="eligible-check">
            {selectedExpense?.eligible ? (
              <TbDiscountCheckFilled />
            ) : (
              <TiTimes className="ineligible" />
            )}
            {selectedExpense?.eligible
              ? "HSA Eligible Expense"
              : "Not Eligible Expense"}
          </h4>

          {selectedExpense?.tags.length > 0 ? (
            <>
              <small>Tags</small>
              {RenderTags()}
            </>
          ) : null}

          <small className={selectedExpense?.tags.length > 0 ? "notes-section-label" : ""}>Notes</small>
          {selectedExpense?.notes ? (
            selectedExpense.notes
          ) : (
            <span className="no-notes-text">No Notes Provided</span>
          )}

          {selectedExpense?.receipts.length > 0 ? (
            <>
              <small>Receipts</small>
              <img src={selectedExpense.receipts[0].base64} />
            </>
          ) : null}
        </div>
      </section>
    );
  };

  

  const dispatchExpenseEvent = (action) => {
    switch (action) {
      case "save":
        const saveExpense = new CustomEvent("save-expense");
        window.dispatchEvent(saveExpense);
        break;
      case "update":
        const updateExpense = new CustomEvent("update-expense");
        window.dispatchEvent(updateExpense);
        break;
      case "delete":
        const deleteExpense = new CustomEvent("delete-expense");
        window.dispatchEvent(deleteExpense);
        break;
      default:
        console.error("no action provided for expense event");
    }
  };

  return (
    <>
      <section className="expense-header">
        {!isDesktop && selectedExpense ? (
          isTightMobile ? (
            <h1>Details</h1>
          ) : (
            <h1>Expense Detail</h1>
          )
        ) : (
          <h1>Expenses</h1>
        )}
        <AiFillPlusCircle
          id="open-add-btn"
          onClick={() => setSelectedExpense()}
        />
      </section>
      {!loadingExpenses ? (
        noExpenses ? (
          <NoExpenses />
        ) : (
          <>
            {!isDesktop ? (
              selectedExpense ? (
                <ExpenseDetail />
              ) : (
                <ExpensesList
                  expenses={expenses[0]?.expenses}
                  setSelectedExpense={setSelectedExpense}
                />
              )
            ) : (
              <section className="desktop-detail-view">
                <ExpensesList
                  expenses={expenses[0]?.expenses}
                  setSelectedExpense={setSelectedExpense}
                />
                {selectedExpense ? <ExpenseDetail /> : null}
              </section>
            )}
          </>
        )
      ) : (
        <LoadingExpenses />
      )}
      <sl-drawer
        placement="bottom"
        class="shoebox-drawer"
        id="add-expense-drawer"
      >
        <span slot="label" class="drawer-header">
          <h1>Add Expense</h1>
          <AiFillPlusCircle id="close-add-btn" />
        </span>
        <ExpenseForm id="add-expense-form" setIsFormValid={setIsFormValid} />
        <sl-button
          slot="footer"
          class="shoebox-drawer-action-button"
          onClick={() => dispatchExpenseEvent("save")}
          id="close-add-btn"
        >
          Save
        </sl-button>
      </sl-drawer>
      <sl-drawer
        placement="bottom"
        class="shoebox-drawer"
        id="edit-expense-drawer"
      >
        <span slot="label" class="drawer-header">
          <h1>Edit Expense</h1>
          <AiFillPlusCircle id="close-edit-btn" />
        </span>
        <ExpenseForm
          id="edit-expense-form"
          setIsFormValid={setIsFormValid}
          selectedExpense={selectedExpense}
          setSelectedExpense={setSelectedExpense}
        />
        <div slot="footer" className="edit-drawer-footer">
          <sl-button
            class="shoebox-drawer-action-button"
            onClick={() => dispatchExpenseEvent("update")}
            id="close-edit-btn"
          >
            Update
          </sl-button>
          {selectedExpense ? (
            <sl-button
              class="shoebox-drawer-action-button"
              onClick={() => dispatchExpenseEvent("delete")}
              id="close-edit-btn"
              variant="danger"
            >
              Delete
            </sl-button>
          ) : null}
        </div>
      </sl-drawer>
    </>
  );
};

export { Expenses };
