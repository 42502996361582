import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { TbClipboardCheck, TbBellPlus } from "react-icons/tb";
import Airtable from "airtable";
import { validateEmail } from "../../hooks-n-utils/email-validation";
import {
  completeNotification,
  initiateNotification,
} from "../../hooks-n-utils/analytics/events/landing";

const Notification = () => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isPhone, setIsPhone] = useState(true);

  const updateWidth = () => {
    setIsPhone(window.innerWidth < 700);
  };

  window.addEventListener("resize", updateWidth);

  let enrolled;

  useEffect(() => {
    enrolled = window.sessionStorage.getItem("in-queue-success");
    if (email.length === 0 && document.getElementById("email-sign-up-button")) {
      document
        .getElementById("email-sign-up-button")
        .setAttribute("disabled", true);
    }
    setSuccess(enrolled);
    updateWidth();
  }, [enrolled, email]);

  const checkInput = (input) => {
    if (validateEmail(input)) {
      setEmail(input);
      setError("");
      setValidEmail(true);
      if (
        document.getElementById("email-sign-up-button").hasAttribute("disabled")
      ) {
        document
          .getElementById("email-sign-up-button")
          .removeAttribute("disabled");
      }
    } else {
      setEmail(input);
      setValidEmail(false);
      if (
        !document
          .getElementById("email-sign-up-button")
          .hasAttribute("disabled")
      ) {
        document
          .getElementById("email-sign-up-button")
          .setAttribute("disabled", true);
      }
    }
  };

  const addSignUp = async (email) => {
    document
      .getElementById("email-sign-up-button")
      .setAttribute("disabled", true);
    setLoading(true);
    const base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_SHOEBOX_SIGN_UP_TOKEN,
    }).base(process.env.REACT_APP_AIRTABLE_SHOEBOX_BASE);
    base("Shoebox Signups").create(
      [
        {
          fields: {
            "Email Address": email,
          },
        },
      ],
      function (err) {
        if (err) {
          console.error(err);
          setLoading(false);
          return;
        } else {
          // Analytics trigger for sign up success
          completeNotification();
          setSuccess(true);
          setLoading(false);
          window.sessionStorage.setItem("in-queue-success", true);
          setEmail("");
        }
      }
    );
  };

  const addNewSignUp = () => {
    setSuccess(false);
    window.sessionStorage.removeItem("in-queue-success");
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (validEmail) {
        addSignUp(email);
      } else {
        setError("Please enter a valid email, ex. maya@shoebox.com");
      }
    }
  };

  return (
    <div className="landing-notification-container">
      <div className="notification-content">
        {isPhone ? (
          <h2>
            Get Notified
            <br /> When We're <span>Live</span>
          </h2>
        ) : (
          <h2>
            Get Notified When We're <span>Live</span>
          </h2>
        )}
        <span className="landing-subheader">
          We'll send you a note once you're able to make an account
        </span>
        {success ? (
          <div className="notification-body-success">
            <TbClipboardCheck />
            <span>
              Success, you're on the list. We'll be reaching out soon!
            </span>
            <span className="add-new-link" onClick={() => addNewSignUp()}>
              <TbBellPlus />
              Add another email
            </span>
          </div>
        ) : (
          <div className="notification-body">
            <div className="notification-input">
              <input
                type="email"
                autoComplete="on"
                onClick={() => initiateNotification()}
                value={email}
                onChange={(e) => checkInput(e.target.value)}
                onKeyDown={(e) => handleEnter(e)}
                placeholder="howdy@theblocks.finance"
              />
              <sl-button
                aria-label="Submit Email"
                type="submit"
                id="email-sign-up-button"
                onClick={() => addSignUp(email)}
              >
                {loading ? (
                  <sl-spinner variant="button-primary"></sl-spinner>
                ) : (
                  <FaArrowRight />
                )}
              </sl-button>
            </div>
            {error !== "" ? <small className="error">{error}</small> : null}
            <small>
              This is used for notification purposes only. Emails will{" "}
              <strong>not</strong> be used for marketing.
            </small>
          </div>
        )}
      </div>
    </div>
  );
};

export { Notification };
