export const titleHandler = (title) => {
    let exceptions = ['of', 'the', 'and'];

    if (!title) {
        return ""
    }

    return title.toLowerCase().split(' ').map((word, i) => {
        return exceptions.includes(word) && i !== 0 ? word : word.charAt(0).toUpperCase().concat(word.substr(1));
    }).join(' ');
}