import React from "react";
import { Oval } from 'react-loader-spinner';

const Loader = () => {

    return(
        <div className="blocks-loader">
            <Oval
                height={60}
                width={60}
                color="#FFC832"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#CECECE"
                strokeWidth={10}
                strokeWidthSecondary={10}
                wrapperClass="mobile-spinner"
            /> 
        </div>
    )
}

const ButtonLoader = () => {
    return(
        <div className="blocks-button-loader">
            <Oval
                height={25}
                width={25}
                color="#FFC832"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#FFFFFF"
                strokeWidth={10}
                strokeWidthSecondary={8}
                wrapperClass="mobile-spinner"
            /> 
        </div>
    )
}

const StyledInputLoader = () => {
    return(
        <div className="blocks-button-loader">
            <Oval
                height={44}
                width={44}
                color="#FFFFFF"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#FFC832"
                strokeWidth={10}
                strokeWidthSecondary={8}
                wrapperClass="mobile-spinner"
            /> 
        </div>
    )
}

export { Loader, ButtonLoader, StyledInputLoader };