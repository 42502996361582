import React, { useEffect, useRef, useState } from 'react';
import { currencyHandler } from '../../hooks-n-utils/currency';
import { FiExternalLink } from 'react-icons/fi';
import { FaHashtag } from 'react-icons/fa';
import { IoReceipt, IoCloseCircle } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { AiFillPlusCircle } from 'react-icons/ai';
import ahc from '../../assets/insurance-graphics/ahc.png';
import bcbs from '../../assets/insurance-graphics/bcbs.png';
import cchc from '../../assets/insurance-graphics/cchc.png';
import chc from '../../assets/insurance-graphics/chc.png';
import hhc from '../../assets/insurance-graphics/hhc.png';
import hphc from '../../assets/insurance-graphics/hphc.png';
import kphc from '../../assets/insurance-graphics/kphc.png';
import uhc from '../../assets/insurance-graphics/uhc.png';
import { useAuth } from '../../contexts/AuthContext';


const ExpenseForm = ({setIsFormValid, selectedExpense, setSelectedExpense}) => {

    const { currentUser, activeUserProfile, addExpense, addExpenseLoading, updateExpense, updateExpenseLoading, deleteExpense, deleteExpenseLoading, hsaExpenseGuide, hasGuide } = useAuth();

    const [ name, setName ] = useState('');
    const [ date, setDate ] = useState('');
    const [ year, setYear ] = useState('');
    const [ maxDate, setMaxDate ] = useState('');
    const [ starterMinDate, setStarterMinDate ] = useState('');
    const [ amount, setAmount ] = useState(0);
    const [ category, setCategory ] = useState('');
    const [ eligible, setEligible ] = useState(true);
    const [ underInsurance, setUnderInsurance ] = useState(false);
    const [ insuranceToggle, setInsuranceToggle ] = useState(false);
    const [ selectedInsurance, setSelectedInsurance ] = useState('');
    const [ selectedLogo, setSelectedLogo ] = useState('');
    const [ isCustomInsurance, setIsCustomInsurance ] = useState(false);
    const [ withdrawn, setWithdrawn ] = useState(false);
    const [ dateWithdrawn, setDateWithdrawn ] = useState('');
    const [ tags, setTags ] = useState([]);
    const [ receipts, setReceipts ] = useState([]);
    const [ notes, setNotes ] = useState('');

    const [ loadedSelectedExpense, setLoadedSelectedExpense ] = useState(false);

    const [ expenseDateError, setExpenseDateError ] = useState('');
    const [ widthdrawnDateError, setWithdrawnDateError ] = useState('');

    const [ addingTags, setAddingTags ] = useState(false);
    const tagRef = useRef();

    let addDrawer = document.getElementById('add-expense-drawer');
    let editDrawer = document.getElementById('edit-expense-drawer');

    let expenseForm = document.querySelector('.expense-form');

    let categoryInput = document.getElementById('expense-category-input');
    let notesInput = document.getElementById('expense-notes-input');

    const resetForm = () => {
      setName('');
      setDate('');
      setYear('');
      setMaxDate('');
      setStarterMinDate('');
      setAmount(0);
      setCategory('');
      setEligible(true);
      setUnderInsurance(false);
      setInsuranceToggle(false);
      setSelectedInsurance('');
      setSelectedLogo('');
      setIsCustomInsurance(false);
      setWithdrawn(false);
      setDateWithdrawn('');
      setTags([]);
      setReceipts([]);
      setNotes('');
    }

    const mapExpenseValues = (selectedExpense) => {
      setName(selectedExpense.name);
      setDate(selectedExpense.date);
      setYear(selectedExpense.year);
      setAmount(selectedExpense.amount);
      setCategory(selectedExpense.category);
      setEligible(selectedExpense.eligible);
      setUnderInsurance(selectedExpense.underInsurance);
      if (selectedExpense.underInsurance) {
        pickInsurance(selectedExpense.selectedInsurance);
      }
      setSelectedInsurance(selectedExpense.selectedInsurance);
      setWithdrawn(selectedExpense.withdrawn);
      setDateWithdrawn(selectedExpense.dateWithdrawn);
      setTags(selectedExpense.tags);
      setReceipts(selectedExpense.receipts);
      setNotes(selectedExpense.notes);
    }

    const handleSave = () => {
      let id;
      let metadata;
      if (selectedExpense) {
        id = selectedExpense.id;
        metadata = {
          createdDate: selectedExpense.metadata.createdDate,
          lastUpdatedDate: new Date()
        }
      } else {
        id = Date.now();
        metadata = {
          createdDate: new Date(),
          lastUpdatedDate: ""
        }
      }
      let payload = {
        id: id,
        name: name,
        date: date,
        year: year,
        amount: amount,
        category: category,
        eligible: eligible,
        underInsurance: underInsurance,
        selectedInsurance: selectedInsurance,
        withdrawn: withdrawn,
        dateWithdrawn: dateWithdrawn,
        tags: tags,
        receipts: receipts,
        notes: notes,
        metadata: metadata
      }
      
      addExpense(payload, currentUser?.uid)
      .then(() => {
        addDrawer.hide();
      })
    }

    const handleUpdate = () => {
      let id;
      let metadata;
      if (selectedExpense) {
        id = selectedExpense.id;
        metadata = {
          createdDate: selectedExpense.metadata.createdDate,
          lastUpdatedDate: new Date()
        }
      } else {
        id = Date.now();
        metadata = {
          createdDate: new Date(),
          lastUpdatedDate: ""
        }
      }
      let payload = {
        id: id,
        name: name,
        date: date,
        year: year,
        amount: amount,
        category: category,
        eligible: eligible,
        underInsurance: underInsurance,
        selectedInsurance: selectedInsurance,
        withdrawn: withdrawn,
        dateWithdrawn: dateWithdrawn,
        tags: tags,
        receipts: receipts,
        notes: notes,
        metadata: metadata
      }

      updateExpense(payload, currentUser?.uid)
      .then(() => {
        setSelectedExpense(payload);
        editDrawer.hide();
      })
    }

    const handleDelete = () => {
      let id;
      let metadata;
      if (selectedExpense) {
        id = selectedExpense.id;
        metadata = {
          createdDate: selectedExpense.metadata.createdDate,
          lastUpdatedDate: new Date()
        }
      } else {
        id = Date.now();
        metadata = {
          createdDate: new Date(),
          lastUpdatedDate: ""
        }
      }
      let payload = {
        id: id,
        name: name,
        date: date,
        year: year,
        amount: amount,
        category: category,
        eligible: eligible,
        underInsurance: underInsurance,
        selectedInsurance: selectedInsurance,
        withdrawn: withdrawn,
        dateWithdrawn: dateWithdrawn,
        tags: tags,
        receipts: receipts,
        notes: notes,
        metadata: metadata
      }

      deleteExpense(payload, currentUser?.uid)
      .then(() => {
        setSelectedExpense();
        editDrawer.hide();
      })
    }

    useEffect(() => {
      let expenseMaxDate = new Date().toJSON().split('T')[0];
      setMaxDate(expenseMaxDate);
      let dateArray = expenseMaxDate.split('-');
      let maxYear = dateArray[0];
      let starterLimitedYear = +maxYear - 2;
      dateArray.splice(0, 1, starterLimitedYear.toString());
      setStarterMinDate(dateArray.join('-'));
      categoryInput?.addEventListener('sl-input', (e) => setCategory(e.target.value));
      notesInput?.addEventListener('sl-input', (e) => setNotes(e.target.value));

      if (selectedExpense && !loadedSelectedExpense) {
        mapExpenseValues(selectedExpense);
      }

      window.addEventListener("sl-after-show", (e) => {
        if (e.srcElement.id === "edit-expense-drawer") {
          if (selectedExpense && !loadedSelectedExpense) {
            mapExpenseValues(selectedExpense);
            setLoadedSelectedExpense(true);
          }
        }
      });
      
      if (name && date && year && amount && category && ((underInsurance && selectedInsurance) || !underInsurance) && ((withdrawn && dateWithdrawn) || !withdrawn) && !expenseDateError && !widthdrawnDateError) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }

      if (!underInsurance) {
        setSelectedInsurance(null);
        document.getElementById('custom-ins-input').value = null;
        setIsCustomInsurance(false);
        setSelectedLogo();
      }

      if (!withdrawn) {
        setDateWithdrawn(null);
      }
      
      window.addEventListener("save-expense", handleSave);
      window.addEventListener("update-expense", handleUpdate);
      window.addEventListener("delete-expense", handleDelete);
      window.addEventListener("sl-after-hide", (e) => {
        if (e.target.className === "shoebox-drawer") {
          resetForm();
        }
        if (e.srcElement.id === "edit-expense-drawer") {
          setLoadedSelectedExpense(false);
        }
      });
      
      return () => {
        window.removeEventListener("save-expense", handleSave);
        window.removeEventListener("update-expense", handleUpdate);
        window.removeEventListener("delete-expense", handleDelete);
        window.removeEventListener("sl-after-hide", () => {});
        window.removeEventListener("sl-after-show", () => {});
      }
    }, [addDrawer, editDrawer, currentUser, expenseForm, selectedExpense, hasGuide, name, date, amount, category, eligible, underInsurance, selectedInsurance, withdrawn, dateWithdrawn, tags, notes, categoryInput, notesInput]);

    const handleExpenseDate = (date) => {
      setDate(date);
      let expenseYear = date?.split('-')[0];
      setYear(expenseYear);
    }

    const pickInsurance = (provider) => {
      setSelectedInsurance(null);
      document.getElementById('custom-ins-input').value = null;
      setIsCustomInsurance(false);
      switch (provider) {
        case 'aetna':
          setSelectedInsurance('aetna');
          setSelectedLogo(ahc);
          break;
        case 'bcbs':
          setSelectedInsurance('bcbs');
          setSelectedLogo(bcbs);
          break;
        case 'uhc':
          setSelectedInsurance('uhc');
          setSelectedLogo(uhc);
          break;
        case 'cigna':
          setSelectedInsurance('cigna');
          setSelectedLogo(chc);
          break;
        case 'humana':
          setSelectedInsurance('humana');
          setSelectedLogo(hhc);
          break;
        case 'kaiser':
          setSelectedInsurance('kaiser');
          setSelectedLogo(kphc);
          break;
        case 'hphc':
          setSelectedInsurance('hphc');
          setSelectedLogo(hphc);
          break;
        case 'centene':
          setSelectedInsurance('centene');
          setSelectedLogo(cchc);
          break;
        default: 
          setSelectedInsurance(null);
          setSelectedLogo();
          break;
      }
    }

    const handleManualInsuranceEntry = (entry) => {
      if (!isCustomInsurance) {
        setIsCustomInsurance(true);
      }
      setSelectedInsurance(entry);
    }

    const toggleTagEntry = () => {
      setAddingTags(!addingTags);
      if (!addingTags) {
        setTimeout(() => {
          document.getElementById('expense-tag-input').focus();
        }, 25)
      }
    }

    const addTag = (tag) => {
      if (tag.trim()) {
        setTags([ ...tags, tag.trim() ]);
        tagRef.current.value = '';
      }
    }

    const handleEnter = (e) => {
      if (e.key === 'Enter') {
        addTag(tagRef.current.value);
      }
    }

    const deleteTag = (index) => {
      let currentTags = [...tags];
      currentTags.splice(index, 1);
      setTags(currentTags);
    }

    const renderTags = () => {
      return (
        <>
          {
            tags.map((tag, index) => {
              return (
                <sl-badge variant="primary" key={index} pill>{tag} <IoCloseCircle onClick={() => deleteTag(index)} /></sl-badge>
              )
            })
          }
        </>
      )
    }

    const getBase64 = (file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let currentReceipts = [...receipts];
        let newReceipt = {
          filename: file.name,
          base64: reader.result
        }
        currentReceipts.push(newReceipt);
        setReceipts(currentReceipts);
      };
      reader.onerror = function (error) {
        console.error('Error: ', error);
      };
    }

    const selectFile = (file) => {
      if (file) {
        getBase64(file);
      }
    }

    const deleteReceipt = (index) => {
      let currentReceipts = [...receipts];
      currentReceipts.splice(index, 1);
      setReceipts(currentReceipts);
    }

    const renderReceipts = () => {
      return (
        <>
          {
            receipts.map((receipt, index) => {
              return (
                <div className='expense-receipt' key={receipt.filename}>
                  <IoCloseCircle onClick={() => deleteReceipt(index)} />
                  <img src={receipt.base64} />
                </div>
              )
            })
          }
        </>
      )
    }

    const validateDate = (date, source) => {
      if (source === 'withdrawn') {
        if (date > maxDate) {
          setWithdrawnDateError('Oops! You\'ve set a future date; only current & past expenses are allowed. Try again!'); 
        } else if (!activeUserProfile?.subActive && date < starterMinDate) {
          setWithdrawnDateError('Oops! Shoebox Starter accounts can only add expenses up to two years in the past.'); 
        } else {
          setWithdrawnDateError('');
        }
      } else {
        if (date > maxDate) {
          setExpenseDateError('Oops! You\'ve set a date in the future, only past expenses can be added.'); 
        } else if (!activeUserProfile?.subActive && date < starterMinDate) {
          setExpenseDateError('Oops! Shoebox Starter accounts can only add expenses up to two years in the past.'); 
        } else {
          setExpenseDateError('');
        }
      }
    }

    return (
      <section className='expense-form'>
          <span className='name-block'>
            <label>Name</label>
            <input type='text' onChange={(e) => setName(e.target.value)} value={name}></input>
          </span>
          {
            expenseDateError ?
            <span className='expense-form-error'>{expenseDateError}</span> :
            null
          }
          <div className='date-amount-block'>
              <span>
                <label>Date</label>
                <input type='date' min={activeUserProfile?.subActive ? null : starterMinDate} max={maxDate} onBlur={() => validateDate(date, 'expense')} onChange={(e) => handleExpenseDate(e.target.value)} value={date}></input>
              </span>
              <span>
                <label>Amount</label>
                <span className='blocks-currency-input'>
                  <span>$</span>
                  <input type='number' className='expense-amount-input' onClick={(e) => e.target.select()} onChange={(e) => setAmount(e.target.value)} onBlur={(e) => setAmount(parseFloat(e.target.value).toFixed(2))} value={amount}></input>
                </span>
              </span>
          </div>
          <sl-select placeholder='Select' id='expense-category-input' value={category} label='Category' clearable filled>
              <sl-option value="coinsurance">Coinsurance</sl-option>
              <sl-option value="deductible">Deductible</sl-option>
              <sl-option value="medication">Medication</sl-option>
              <sl-option value="out-of-pocket">Out of Pocket</sl-option>
          </sl-select>
          <div className='hsa-eligible-block'>
              <sl-radio-group label="HSA Eligible?" id='expense-eligibility-input' value={eligible}>
                  <sl-radio-button onClick={() => setEligible(true)} value={true}>Yes</sl-radio-button>
                  <sl-radio-button onClick={() => setEligible(false)} value={false}>No</sl-radio-button>
              </sl-radio-group>
              {
                hasGuide ?
                <span>
                  <p>Not Sure?</p>
                  <a href={hsaExpenseGuide[0].url} target='_blank'>Check out this guide <FiExternalLink /></a>
                </span> :
                null
              }
              
          </div>
          <label className='insurance-claim-label'>Claim Under Insurance?</label>
          <div className='insurance-claim-block'>
            <sl-radio-group value={underInsurance}>
              <sl-radio-button value={true} onClick={() => setUnderInsurance(true)}>Yes</sl-radio-button>
              <sl-radio-button value={false} onClick={() => setUnderInsurance(false)}>No</sl-radio-button>
            </sl-radio-group>
            {
              underInsurance ?
              <sl-button class='expense' onClick={() => setInsuranceToggle(!insuranceToggle)} variant={insuranceToggle ? 'default' : 'neutral'}>{!insuranceToggle ? selectedInsurance ? 'Update Insurance' : 'Select Insurance' : 'Set Insurance'}</sl-button> :
              null
            }
          </div>
          {
            !insuranceToggle && selectedInsurance ?
            <div className='selected-insurance-display'>
              <small>Selected Insurance: </small>
              {
                isCustomInsurance ?
                <span className='manual-selection-display'>{selectedInsurance}</span> :
                <img src={selectedLogo} />
              }
            </div> :
            null
          }
          <div className={underInsurance && insuranceToggle ? 'insurance-option-tiles expanded' : 'insurance-option-tiles'}>
            <div className='button-row'>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'aetna' ? pickInsurance() : pickInsurance('aetna')} class={selectedInsurance === 'aetna' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={ahc} />
              </sl-button>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'bcbs' ? pickInsurance() : pickInsurance('bcbs')} class={selectedInsurance === 'bcbs' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={bcbs} />
              </sl-button>
            </div>
            <div className='button-row'>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'uhc' ? pickInsurance() : pickInsurance('uhc')} class={selectedInsurance === 'uhc' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={uhc} />
              </sl-button>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'cigna' ? pickInsurance() : pickInsurance('cigna')} class={selectedInsurance === 'cigna' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={chc} />
              </sl-button>
            </div>
            <div className='button-row'>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'humana' ? pickInsurance() : pickInsurance('humana')} class={selectedInsurance === 'humana' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={hhc} />
              </sl-button>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'kaiser' ? pickInsurance() : pickInsurance('kaiser')} class={selectedInsurance === 'kaiser' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={kphc} />
              </sl-button>
            </div>
            <div className='button-row'>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'hphc' ? pickInsurance() : pickInsurance('hphc')} class={selectedInsurance === 'hphc' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={hphc} />
              </sl-button>
              <sl-button id='blocks-icon-button' onClick={() => selectedInsurance === 'centene' ? pickInsurance() : pickInsurance('centene')} class={selectedInsurance === 'centene' ? "insurance-tile selected" : "insurance-tile"} variant='neutral'>
                <img src={cchc} />
              </sl-button>
            </div>
            <input placeholder="Other (please specify)" id='custom-ins-input' onChange={(e) => handleManualInsuranceEntry(e.target.value)}></input>
          </div>
          <label className='withdrawn-expense-label'>Withdrawn from HSA Balance?</label>
          {
            widthdrawnDateError ?
            <span className='expense-form-error'>{widthdrawnDateError}</span> :
            null
          }
          <div className='withdrawn-expense-block'>
            <sl-radio-group value={withdrawn}>
              <sl-radio-button value={true} onClick={() => setWithdrawn(true)}>Yes</sl-radio-button>
              <sl-radio-button value={false} onClick={() => setWithdrawn(false)}>No</sl-radio-button>
            </sl-radio-group>
            {
              withdrawn ?
              <input type='date' min={activeUserProfile?.subActive ? null : starterMinDate} max={maxDate} onBlur={() => validateDate(dateWithdrawn, 'withdrawn')} onChange={(e) => setDateWithdrawn(e.target.value)}></input> :
              null
            }
          </div>
          <div>

          </div>
          <label>Tags & Receipts <span className='optional-indicator'>optional</span></label>
          <div className={addingTags ? 'add-tags-state' : 'button-row'}>
            <sl-button id='blocks-icon-button' onClick={() => toggleTagEntry()} class='expense add-tags-btn' style={addingTags ? {width: "117px"} : {width: "100%", maxWidth: "300px"}} variant='neutral'>
              { addingTags ? <HiOutlineArrowLeft /> : <FaHashtag /> }
              <span>{addingTags ? 'All Done' : 'Add Tags'}</span>
            </sl-button>
            {
              addingTags ?
              <input ref={tagRef} id='expense-tag-input' onKeyDown={handleEnter} maxLength={30}></input> :
              <div className="styled-input-blocks-button">
                <label><input type='file' accept="image/jpg, image/jpeg, image/png" onChange={(e) => selectFile(e.target.files[0])}/><span><IoReceipt /> Add Reciept</span></label> 
              </div>
            }
            {
              addingTags ?
              <sl-button class='add-tag-plus-btn' onClick={() => addTag(tagRef.current.value)}><AiFillPlusCircle /></sl-button> :
              null
            }
          </div>
          <div className='tag-list'>{renderTags()}</div>
          <div className='receipt-list'>{renderReceipts()}</div>
          <span className='notes-block'>
            <label>Notes <span className='optional-indicator'>optional</span></label>
            <textarea onChange={(e) => setNotes(e.target.value)}></textarea>
          </span>
      </section>
    )
}

export {ExpenseForm}