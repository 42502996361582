import ReactGA from 'react-ga4';

export const navigateToLandingSection = (sectionTitle) => {
    ReactGA.event({
        category: "landing",
        action: "landing_section_click",
        label: sectionTitle
    });
}

export const initiateNotification = () => {
    ReactGA.event({
        category: "landing",
        action: "landing_notification_input_click",
        label: "begin_notification_entry"
    });
}

export const completeNotification = () => {
    ReactGA.event({
        category: "landing",
        action: "landing_notification_submit",
        label: "notification_submission_success"
    });
}