import { collection, onSnapshot, addDoc } from "firebase/firestore";
import { db } from "../firebase";

export const createProCheckoutSession = async (uid) => {
    // create checkout session subcollection in the user's document
    const collectionRef = collection(db, "users", uid, "checkout_sessions");
    const sessionRef = await addDoc(collectionRef, {
        price: "price_1MoGRPEUIBjkNKHlSeR1walB",
        // price: "price_1MnWnlEUIBjkNKHlX6vcO2Ef",
        success_url: window.location.origin,
        cance_url: window.location.origin,
    })
    const unsubscribe = onSnapshot(sessionRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
            console.error(error);
            return unsubscribe();
        }
        if (url) {
            window.location.assign(url);
            return unsubscribe();
        }
    })
}