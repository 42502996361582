import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Footer } from "../Footer";
import { HiDocumentArrowDown } from "react-icons/hi2";

const PrivacyPolicy = () => {
  const { currentUser, getLegalDocs, legalDocs, loadingDocs } = useAuth();

  const [privacyPolicy, setPrivacyPolicy] = useState();

  useEffect(() => {
    if (!legalDocs) {
      getLegalDocs();
    }
    if (legalDocs) {
      legalDocs.map((doc) => {
        if (doc.categories.hasOwnProperty("S Privacy Policy")) {
          setPrivacyPolicy(doc);
        }
      });
    }
  }, [legalDocs]);

  return (
    <div className="privacy-policy-page">
      <div className="header">
        <h1>Privacy Policy</h1>
        <a
          target="_blank"
          href="https://firebasestorage.googleapis.com/v0/b/shoebox-dev-app.appspot.com/o/global-items%2Fresources%2Flegal%2FShoebox%20Privacy%20Policy%20-%20October%202023.pdf?alt=media&token=00c19da3-51e0-4748-994c-48aa854d66d7&_gl=1*1hl0rmw*_ga*NDA0NDE0MDA4LjE2OTEzMzczMzI.*_ga_CW55HF8NVT*MTY5NjI2OTQ2MS4xMTMuMS4xNjk2MjY5NTExLjEwLjAuMA.."
        >
          Download Policy
          <HiDocumentArrowDown />
        </a>
      </div>

      {!loadingDocs ? (
        privacyPolicy ? (
          <section
            className={
              currentUser
                ? "privacy-policy-body"
                : "privacy-policy-body has-footer"
            }
            dangerouslySetInnerHTML={{ __html: privacyPolicy?.content }}
          ></section>
        ) : (
          <div className="policy-error-text">
            There was an error while retrieving the Privacy Policy. Please try
            again later.
          </div>
        )
      ) : (
        <div className="policy-loader">
          <sl-spinner></sl-spinner>
        </div>
      )}
      {!currentUser && <Footer />}
    </div>
  );
};

export { PrivacyPolicy };
