import React from 'react';
import { useLocation } from 'react-router-dom';

const PersonalInfo = () => {
    const location = useLocation();

    return (
        <h1>Personal Info</h1>
    )
}

export { PersonalInfo }