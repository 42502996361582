import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Footer } from "../Footer";
import { HiDocumentArrowDown } from "react-icons/hi2";

const TermsOfUse = () => {
  const { currentUser, getLegalDocs, legalDocs, loadingDocs } = useAuth();

  const [termsOfUse, setTermsOfUse] = useState();

  useEffect(() => {
    if (!legalDocs) {
      getLegalDocs();
    }
    if (legalDocs) {
      legalDocs.map((doc) => {
        if (doc.categories.hasOwnProperty("S Terms of Use")) {
          setTermsOfUse(doc);
        }
      });
    }
  }, [legalDocs]);
  return (
    <div className="terms-of-use-page">
      <div className="header">
        <h1>Terms of Use</h1>
        {termsOfUse && (
          <a
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/shoebox-dev-app.appspot.com/o/global-items%2Fresources%2Flegal%2FShoebox%20Terms%20of%20Use%20-%20October%202023.pdf?alt=media&token=70830368-2518-4abf-9288-a392a19398ff&_gl=1*gbdutw*_ga*NDA0NDE0MDA4LjE2OTEzMzczMzI.*_ga_CW55HF8NVT*MTY5NjI2OTQ2MS4xMTMuMS4xNjk2MjcwNzM4LjYwLjAuMA.."
          >
            Download Terms
            <HiDocumentArrowDown />
          </a>
        )}
      </div>
      {!loadingDocs ? (
        termsOfUse ? (
          <section
            className={
              currentUser ? "terms-of-use-body" : "terms-of-use-body has-footer"
            }
            dangerouslySetInnerHTML={{ __html: termsOfUse?.content }}
          ></section>
        ) : (
          <div className="terms-error-text">
            There was an error while retrieving the Terms of Use. Please try
            again later.
          </div>
        )
      ) : (
        <div className="terms-loader">
          <sl-spinner></sl-spinner>
        </div>
      )}
      {!currentUser && <Footer />}
    </div>
  );
};

export { TermsOfUse };
