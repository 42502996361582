import "./App.scss";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { SignIn } from "./components/authentication/SignIn";
import {
  PublicRoute,
  PrivateRoute,
} from "./components/authentication/RouteRestrictions";
import { NotFound } from "./components/global/404";
import { Expenses } from "./components/expenses/Expenses";
import { Portfolio } from "./components/portfolio/Portfolio";
import { Profile } from "./components/profile/Profile";
import { Header } from "./components/global/Header";
import { ForgotPassword } from "./components/authentication/ForgotPassword";
import { Landing } from "./components/external/Landing";
import { Tabs } from "./components/global/Tabs";
import { CreateAccount } from "./components/external/CreateAccount";
import { Compare } from "./components/external/Compare";
import { Setup } from "./components/authentication/Setup";
import { Support } from "./components/global/Support";
import { Feedback } from "./components/global/Feedback";
import { About } from "./components/global/About";
import { PersonalInfo } from "./components/profile/PersonalInfo";
import { Subscription } from "./components/profile/Subscription";
import { Billing } from "./components/profile/Billing";
import { AccountUpdates } from "./components/profile/AccountUpdates";
import { Footer } from "./components/global/Footer";
import { Resources } from "./components/external/Resources";
import { FAQ } from "./components/external/FAQ";
import { PrivacyPolicy } from "./components/global/Legal/PrivacyPolicy";
import { TermsOfUse } from "./components/global/Legal/TermsOfUse";

const App = () => {
  const [darkMode, setDarkMode] = useState();

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  function changeThemeColor(mode) {
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute(
      "content",
      mode === "dark" ? "#131313" : "#ffffff"
    );
  }

  let prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  let prefersLight = window.matchMedia("(prefers-color-scheme: light)").matches;

  // magic for system color mode
  let currentSystemTheme = window.matchMedia("(prefers-color-scheme: dark)");
  currentSystemTheme.addEventListener("change", (e) => {});

  const handleColorMode = (colorPreference) => {
    document
      .getElementById("shoebox-index")
      .classList.remove("sl-theme-shoebox-light");
    document
      .getElementById("shoebox-index")
      .classList.remove("sl-theme-shoebox-dark");
    switch (colorPreference) {
      case "system":
        if (prefersDark) {
          setDark();
          changeThemeColor("dark");
          setDarkMode(true);
          document
            .getElementById("shoebox-index")
            .classList.add("sl-theme-shoebox-dark");
        } else if (prefersLight) {
          setLight();
          changeThemeColor("light");
          setDarkMode(false);
          document
            .getElementById("shoebox-index")
            .classList.add("sl-theme-shoebox-light");
        }
        break;
      case "light":
        setLight();
        changeThemeColor("light");
        setDarkMode(false);
        document
          .getElementById("shoebox-index")
          .classList.add("sl-theme-shoebox-light");
        break;
      case "dark":
        setDark();
        changeThemeColor("dark");
        setDarkMode(true);
        document
          .getElementById("shoebox-index")
          .classList.add("sl-theme-shoebox-dark");
        break;
      default:
        setLight();
        changeThemeColor("light");
        setDarkMode(false);
        document
          .getElementById("shoebox-index")
          .classList.add("sl-theme-shoebox-light");
    }
  };

  const ScrollToTop = (props) => {
    const location = useLocation();

    useEffect(() => {
      if (!window.sessionStorage.getItem("lastPosition")) {
        window.sessionStorage.setItem("lastPosition", "");
      }
      let lastPosition = window.sessionStorage.getItem("lastPosition");
      if (lastPosition === "") {
        window.sessionStorage.setItem("lastPosition", location.pathname);
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      }
      if (lastPosition !== location.pathname) {
        window.sessionStorage.setItem("lastPosition", location.pathname);
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      }
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: location.pathname,
      });
    }, [location]);

    return <>{props.children}</>;
  };

  document.addEventListener("touchstart", function () {}, true);

  window.addEventListener("scroll", () => {
    document.documentElement.style.setProperty(
      "--scroll-y",
      `${window.scrollY}px`
    );
  });

  const watchForHover = () => {
    // lastTouchTime is used for ignoring emulated mousemove events
    let lastTouchTime = 0;

    function enableHover() {
      if (new Date() - lastTouchTime < 500) return;
      document.body.classList.add("hasHover");
    }

    function disableHover() {
      document.body.classList.remove("hasHover");
    }

    function updateLastTouchTime() {
      lastTouchTime = new Date();
    }

    document.addEventListener("touchstart", updateLastTouchTime, true);
    document.addEventListener("touchstart", disableHover, true);
    document.addEventListener("mousemove", enableHover, true);

    enableHover();
  };

  watchForHover();

  ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);

  return (
    <div>
      <Router>
        <ScrollToTop>
          <AuthProvider handleColorMode={handleColorMode}>
            <section className="header" id="app-header">
              <Header />
            </section>
            <section className="content">
              <Routes>
                <Route element={<PublicRoute />}>
                  <Route exact path="/" element={<Landing />} />
                  {/* <Route path="/hsa-resources" element={<Resources />} />
                  <Route path="/faq" element={<FAQ />} /> */}
                  {/* <Route path='/create-account' element={<CreateAccount />} />
                  <Route path='/compare' element={<Compare />} />
                  <Route path='/sign-in' element={<SignIn />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} /> */}
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    exact
                    path="/"
                    element={<Navigate to="/expenses" replace />}
                  />
                  <Route path="/setup" element={<Setup />} />
                  <Route path="/expenses" element={<Expenses />} />
                  <Route path="/portfolio" element={<Portfolio />} />
                  <Route
                    path="/profile"
                    element={
                      <Profile
                        handleColorMode={handleColorMode}
                        prefersDark={prefersDark}
                        prefersLight={prefersLight}
                      />
                    }
                  />
                  <Route
                    path="/profile/personal-info"
                    element={<PersonalInfo />}
                  />
                  <Route
                    path="/profile/edit-picture"
                    element={<PersonalInfo />}
                  />
                  <Route
                    path="/profile/subscription"
                    element={<Subscription />}
                  />
                  <Route path="/profile/billing" element={<Billing />} />
                  <Route
                    path="/profile/update-email"
                    element={<AccountUpdates />}
                  />
                  <Route
                    path="/profile/update-password"
                    element={<AccountUpdates />}
                  />
                  <Route path="/support" element={<Support />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/about" element={<About />} />
                </Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Routes>
            </section>
            <Tabs />
          </AuthProvider>
        </ScrollToTop>
      </Router>
    </div>
  );
};
export default App;
