import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { IoPersonCircleOutline, IoClose } from "react-icons/io5";
import { HiMenuAlt4 } from "react-icons/hi";
import Shoebox from "../../assets/Shoebox_Filebox.webp";
import { navigateToLandingSection } from "../../hooks-n-utils/analytics/events/landing";

const Header = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const landingSectionNavigation = (section) => {
    if (isDesktop) {
      navigateToLandingSection(section);
    } else {
      navigateToLandingSection(section);
      toggleMenu();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
  };

  window.addEventListener("resize", updateWidth);

  const NavigationItems = () => {
    if (
      currentUser ||
      location.pathname.includes("/sign-in") ||
      location.pathname.includes("/create-account")
    ) {
      if (
        location.pathname.includes("/sign-in") ||
        location.pathname.includes("/create-account")
      ) {
        return;
      } else {
        return (
          <nav className="desktop-nav">
            {location.pathname.includes("expenses") ? (
              <div
                className={
                  location.pathname.includes("expenses")
                    ? "menu-link active"
                    : "menu-link"
                }
              >
                Expenses
              </div>
            ) : (
              <Link
                to="/"
                className={
                  location.pathname.includes("expenses")
                    ? "menu-link active"
                    : "menu-link"
                }
              >
                Expenses
              </Link>
            )}
            {location.pathname.includes("portfolio") ? (
              <div
                className={
                  location.pathname.includes("portfolio")
                    ? "menu-link active"
                    : "menu-link"
                }
              >
                Portfolio
              </div>
            ) : (
              <Link
                to="/portfolio"
                className={
                  location.pathname.includes("portfolio")
                    ? "menu-link active"
                    : "menu-link"
                }
              >
                Portfolio
              </Link>
            )}
          </nav>
        );
      }
    } else {
      return (
        <nav>
          <a
            href="/#features"
            onClick={() => landingSectionNavigation("features")}
            className={
              location.pathname.includes("/#features") ? "active" : null
            }
          >
            Features
          </a>
          <a
            href="/#stats"
            onClick={() => landingSectionNavigation("stats")}
            className={location.pathname === "/#stats" ? "active" : null}
          >
            Stats
          </a>
          <a
            href="/#pricing"
            onClick={() => landingSectionNavigation("pricing")}
            className={
              location.pathname.includes("/#pricing") ? "active" : null
            }
          >
            Pricing
          </a>
          <a
            href="/#additional-tools"
            onClick={() => landingSectionNavigation("additional_tools")}
            className={
              location.pathname.includes("/#additional-tools") ? "active" : null
            }
          >
            Additional Tools
          </a>
          <a href="/#notify-me">
            <sl-button aria-label="Get In Line" class="blocks-pill-btn" pill>
              Get In Line
            </sl-button>
          </a>
        </nav>
      );
    }
  };

  const scrollHandler = () => {
    if (location.pathname === "/") {
      window.location.hash = "";
      navigateToLandingSection("Shoebox - Home");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (location.pathname.includes("expenses")) {
      return;
    } else {
      navigate("/");
    }
  };

  return (
    <div className="global-header">
      <div className="header-content">
        <section className="branding" onClick={() => scrollHandler()}>
          <img alt="Shoebox logo" className="shoebox-brand" src={Shoebox} />
          {currentUser ? null : <h1>Shoebox</h1>}
        </section>
        <section className="global-controls">
          {isDesktop && currentUser ? <NavigationItems /> : null}
          {!currentUser ? (
            <div>
              <div className="desktop-menu">
                <NavigationItems />
              </div>
              <div className="mobile-menu">
                {location.pathname.includes("/sign-in") ||
                location.pathname.includes("/create-account") ? null : (
                  <div className="mobile-header">
                    <HiMenuAlt4 className="menu-toggle" onClick={toggleMenu} />
                  </div>
                )}
                <div
                  className="mobile-overlay"
                  onClick={toggleMenu}
                  id={showMenu ? "" : "overlay-hidden"}
                ></div>
                <div
                  className="menu-container"
                  id={showMenu ? "" : "container-hidden"}
                >
                  <div className="close">
                    <IoClose onClick={toggleMenu} />
                  </div>
                  <NavigationItems />
                </div>
              </div>
            </div>
          ) : null}
          {currentUser && location.pathname !== "/setup" ? (
            <Link to="/profile">
              <div
                className={
                  location.pathname.includes("/profile") ||
                  location.pathname.includes("/support") ||
                  location.pathname.includes("/feedback") ||
                  location.pathname.includes("/about")
                    ? "profile active"
                    : "profile"
                }
              >
                <IoPersonCircleOutline />
              </div>
            </Link>
          ) : null}
        </section>
      </div>
    </div>
  );
};

export { Header };
