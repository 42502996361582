import React from "react";
import { Link } from "react-router-dom";
import BlocksDark from "../../assets/landing/TheBlocksDark.svg";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <section className="footer-links">
          {/* <div className="link-section">
            <h4>Shoebox</h4>
            <Link to="/hsa-resources#education">HSA Education</Link>
            <Link to="/hsa-resources#irs">IRS Links</Link>
            <Link to="/faq">FAQ</Link>
          </div> */}

          <div className="link-section">
            <h4>The Blocks</h4>
            <a href="https://theblocks.finance/resources" target="_blank">
              Apps & Resources
            </a>
            <a href="https://theblocks.finance/roadmap" target="_blank">
              Roadmap
            </a>
            <a href="https://theblocks.finance/about" target="_blank">
              About
            </a>
          </div>

          <div className="link-section">
            <h4>Resources</h4>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </section>
        <section className="footer-copyright">
          <img alt="The Blocks Icon" src={BlocksDark} />
          <div>
            <span>The Blocks Lab LLC &copy; {new Date().getFullYear()}.</span>{" "}
            <span>All Rights Reserved.</span>
          </div>
        </section>
      </div>
    </footer>
  );
};

export { Footer };
