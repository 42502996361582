import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';

const Investments = () => {

    const { activeUserProfile } = useAuth();

    const [ activeInvestments, setActiveInvestments ] = useState(false);
    const [ isPro, setIsPro ] = useState(false);
    const [ proIndexPortfolio, setProIndexPortfolio ] = useState(false);
    const [ proCustomPortfolio, setProCustomPortfolio ] = useState(false);
    const [ selectedIndex, setSelectedIndex ] = useState(null);
    const [ editingIndex, setEditingIndex ] = useState(false);

    useEffect(() => {
        if (activeUserProfile) {
            setIsPro(activeUserProfile.subActive && activeUserProfile.subscription === 'shoebox-pro');
        }
        if (!selectedIndex || selectedIndex === null) {
            document.getElementById('select-index-btn')?.setAttribute('disabled', 'disabled');
        } else {
            document.getElementById('select-index-btn')?.removeAttribute('disabled');
        }
    }, [activeUserProfile, activeInvestments, selectedIndex, proIndexPortfolio, editingIndex])

    const handleIndexSelection = (index) => {
        setSelectedIndex(index);
    }

    const doNotTrack = () => {
        setSelectedIndex(null);
        setEditingIndex(false);
        document.getElementById('select-index-btn').setAttribute('disabled', 'disabled');
        setProIndexPortfolio(false);
        setActiveInvestments(false);
    }

    const IndexSelector = ({selectedIndex, setEditingIndex, handleIndexSelection}) => {
        
        return (
            <section className='index-selector'>
                <span>Choose a broad-market index to track & drive your HSA portfolio growth:</span>
                <div className='index-tiles'>
                    <div className='button-row'>
                        <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'S&P 500' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'S&P 500', title: "Standard & Poor's 500"})} class={selectedIndex?.symbol === 'S&P 500' ? "index-tile selected" : "index-tile"} variant='neutral'>
                            <h2>S&P 500</h2>
                            <small>Standard & Poor's 500</small>
                        </div>
                        <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'NASDAQ' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'NASDAQ', title: "Nasdaq Composite Index"})} class={selectedIndex?.symbol === 'NASDAQ' ? "index-tile selected" : "index-tile"} variant='neutral'>
                            <h2>NASDAQ</h2>
                            <small>Nasdaq Composite Index</small>
                        </div>
                    </div>
                    <div className='button-row'>
                        <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'DJIA' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'DJIA', title: "Dow Jones Industrial Average"})} class={selectedIndex?.symbol === 'DJIA' ? "index-tile selected" : "index-tile"} variant='neutral'>
                            <h2>DJIA</h2>
                            <small>Dow Jones Industrial Average</small>
                        </div>
                        <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'VTSAX' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'VTSAX', title: "Vanguard Total Stock Market"})} class={selectedIndex?.symbol === 'VTSAX' ? "index-tile selected" : "index-tile"} variant='neutral'>
                            <h2>VTSAX</h2>
                            <small>Vanguard Total Stock Market</small>
                        </div>
                    </div>
                </div>
                <div className='index-selection-buttons'>
                    <sl-button id='select-index-btn' onClick={() => setEditingIndex(false)}>Select {selectedIndex?.symbol ? selectedIndex?.symbol : 'Index'}</sl-button>
                    <sl-button id='cancel-index-btn' onClick={() => doNotTrack()} variant="neutral">Don't Track Index</sl-button>
                </div>
            </section>
        )
    }

    const SelectedIndexCard = ({selectedIndex, setEditingIndex, isPro}) => {
        return (
            <>
                <section className='selected-index-card'>
                    <div className='selected-index-header'>
                        <div className='index-header-symbol'>
                            <h2>{selectedIndex?.symbol}</h2>
                            <small>{selectedIndex?.title}</small>
                        </div>
                        {
                            isPro ?
                            null :
                            <div className='index-header-starter-badge'>STARTER</div>
                        }
                    </div>
                    <span className='selected-index-body-text'>
                        Your portfolio is tracking the {selectedIndex?.title}.
                    </span>
                </section>
                <div className={isPro ? 'edit-index-buttons pro-edit-option' : 'edit-index-buttons'}>
                        <sl-button variant="neutral" onClick={() => setEditingIndex(true)}>Edit Index</sl-button>
                        {/* Second button will show switch to custom portfolio option once launched, currently null */}
                        {/* <sl-button>Switch to Custom Portfolio</sl-button>  */}
                        {
                            isPro ?
                            null :
                            <sl-button>Upgrade to <span id="pro-upgrade-marker">PRO</span></sl-button>
                        }
                </div>
            </>
        )
    }

    return (
        <section className='investments-panel'>
            {
                activeInvestments ?
                isPro ?
                <div>
                    {
                        proIndexPortfolio || proCustomPortfolio ?
                        <div>
                            {
                                selectedIndex && !editingIndex ?
                                <SelectedIndexCard selectedIndex={selectedIndex} setEditingIndex={setEditingIndex} isPro={isPro} /> :
                                <section className='index-selector'>
                                    <span>Choose a broad-market index to track & drive your HSA portfolio growth:</span>
                                    <div className='index-tiles'>
                                        <div className='button-row'>
                                            <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'S&P 500' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'S&P 500', title: "Standard & Poor's 500"})} class={selectedIndex?.symbol === 'S&P 500' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                                <h2>S&P 500</h2>
                                                <small>Standard & Poor's 500</small>
                                            </div>
                                            <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'NASDAQ' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'NASDAQ', title: "Nasdaq Composite Index"})} class={selectedIndex?.symbol === 'NASDAQ' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                                <h2>NASDAQ</h2>
                                                <small>Nasdaq Composite Index</small>
                                            </div>
                                        </div>
                                        <div className='button-row'>
                                            <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'DJIA' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'DJIA', title: "Dow Jones Industrial Average"})} class={selectedIndex?.symbol === 'DJIA' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                                <h2>DJIA</h2>
                                                <small>Dow Jones Industrial Average</small>
                                            </div>
                                            <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'VTSAX' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'VTSAX', title: "Vanguard Total Stock Market"})} class={selectedIndex?.symbol === 'VTSAX' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                                <h2>VTSAX</h2>
                                                <small>Vanguard Total Stock Market</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='index-selection-buttons'>
                                        <sl-button id='select-index-btn' onClick={() => setEditingIndex(false)}>Select {selectedIndex?.symbol ? selectedIndex?.symbol : 'Index'}</sl-button>
                                        <sl-button id='cancel-index-btn' onClick={() => doNotTrack()} variant="neutral">Don't Track Index</sl-button>
                                    </div>
                                </section>
                            }
                        </div> :
                        <div className='pro-portfolio-selector'>
                            <sl-button onClick={() => { setProIndexPortfolio(true); setEditingIndex(true); }}>Track by Index</sl-button>
                            <span>
                                <sl-button disabled>Customize My Portfolio</sl-button>
                                <small>Coming Soon</small>
                            </span>
                        </div>
                    }
                </div> :
                <div>
                    {
                        selectedIndex && editingIndex ?
                        <SelectedIndexCard selectedIndex={selectedIndex} setEditingIndex={setEditingIndex} isPro={isPro} /> :
                        <section className='index-selector'>
                            <span>Choose a broad-market index to track & drive your HSA portfolio growth:</span>
                            <div className='index-tiles'>
                                <div className='button-row'>
                                    <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'S&P 500' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'S&P 500', title: "Standard & Poor's 500"})} class={selectedIndex?.symbol === 'S&P 500' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                        <h2>S&P 500</h2>
                                        <small>Standard & Poor's 500</small>
                                    </div>
                                    <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'NASDAQ' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'NASDAQ', title: "Nasdaq Composite Index"})} class={selectedIndex?.symbol === 'NASDAQ' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                        <h2>NASDAQ</h2>
                                        <small>Nasdaq Composite Index</small>
                                    </div>
                                </div>
                                <div className='button-row'>
                                    <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'DJIA' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'DJIA', title: "Dow Jones Industrial Average"})} class={selectedIndex?.symbol === 'DJIA' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                        <h2>DJIA</h2>
                                        <small>Dow Jones Industrial Average</small>
                                    </div>
                                    <div id='blocks-icon-button' onClick={() => selectedIndex?.symbol === 'VTSAX' ? handleIndexSelection(null) : handleIndexSelection({symbol: 'VTSAX', title: "Vanguard Total Stock Market"})} class={selectedIndex?.symbol === 'VTSAX' ? "index-tile selected" : "index-tile"} variant='neutral'>
                                        <h2>VTSAX</h2>
                                        <small>Vanguard Total Stock Market</small>
                                    </div>
                                </div>
                            </div>
                            <div className='index-selection-buttons'>
                                <sl-button id='select-index-btn' onClick={() => setEditingIndex(false)}>Select {selectedIndex?.symbol ? selectedIndex?.symbol : 'Index'}</sl-button>
                                <sl-button id='cancel-index-btn' onClick={() => doNotTrack()} variant="neutral">Don't Track Index</sl-button>
                            </div>
                        </section>
                    }
                </div> :
                <sl-button class="set-investments-btn" onClick={() => setActiveInvestments(true)}>Set Investments</sl-button>
            }
        </section>
    )
}

export { Investments };