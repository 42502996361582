import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, Link } from 'react-router-dom';
import { HiArchiveBox, HiBriefcase } from 'react-icons/hi2';

const Tabs = () => {

    const { currentUser } = useAuth();
    const location = useLocation();
    const [ isDesktop, setIsDesktop ] = useState(true);

    useEffect(() => {
        updateWidth();
    }, [])

    const updateWidth = () => {
        setIsDesktop(window.innerWidth > 740);
    }

    window.addEventListener("resize", updateWidth);

    return (
        <>
            {
                currentUser && !isDesktop && location.pathname !== '/setup' ?
                <section className="tabs-container">
                    <Link to='/expenses' className={location.pathname ==='/expenses' ? 'active' : null}>
                        <div id={location.pathname ==='/expenses' ? 'active-dot' : 'dot'}></div>
                        <HiArchiveBox />
                        Expenses
                    </Link>
                    <Link to='portfolio' className={location.pathname ==='/portfolio' ? 'active' : null}>
                        <div id={location.pathname ==='/portfolio' ? 'active-dot' : 'dot'}></div>
                        <HiBriefcase />
                        Portfolio
                    </Link>
                </section> :
                null
            }
        </>
    )
}

export { Tabs };