import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import {
  IoPieChart,
  IoGrid,
  IoPhonePortrait,
  IoTabletPortrait,
  IoLaptop,
  IoDocumentAttach,
  IoChatbubbles,
} from "react-icons/io5";
import { Loader } from "../global/Loader";

const Pricing = () => {
  const { pricing } = useAuth();

  const [isDesktop, setIsDesktop] = useState(true);
  const [isPhone, setIsPhone] = useState(true);

  let tierPanel;

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 800);
  };

  window.addEventListener("resize", updateWidth);

  return (
    <section className="pricing-block">
      <section className="price-dots">
        <div className="pricing-dot">
          <h3 className="tier-title">Monthly Billing</h3>
          <span className="trial-chip">7-Day Free Trial</span>
          <div className="dot-price">$9</div>
          <span className="tier-term">per month</span>
        </div>
        <span className="or-divider">OR</span>
        <div className="pricing-dot">
          <span className="savings-chip">9% Savings</span>
          <h3 className="tier-title">Annual Billing</h3>
          <span className="trial-chip">7-Day Free Trial</span>
          <div className="dot-price">$98</div>
          <span className="tier-term">per year</span>
        </div>
      </section>
      <section className="product-features">
        <div className="feature-block">
          <span className="feature-graphic unlimited">Unlimited</span>
          <span className="feature-description">Expenses per Year</span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic">
            <IoPieChart /> <IoGrid />
          </span>
          <span className="feature-description">
            Customized Portfolio Tracking
          </span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic devices">
            <IoPhonePortrait className="phone" />
            <IoTabletPortrait className="tablet" />
            <IoLaptop className="laptop" />
          </span>
          <span className="feature-description">
            Access on All Your Devices
          </span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic">
            <IoDocumentAttach />
          </span>
          <span className="feature-description">Data Export to PDF</span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic">
            <IoChatbubbles />
          </span>
          <span className="feature-description">Active Support</span>
        </div>
      </section>
    </section>
  );
};

export { Pricing };
