import React, { useEffect } from 'react';
import { dateHandler } from '../../hooks-n-utils/date';
import { currencyHandler } from '../../hooks-n-utils/currency';
import { IoIosArrowForward } from 'react-icons/io';

const ExpensesList = ({expenses, setSelectedExpense}) => {

    return (
        <section className='expense-list'>
            <div className='year-header'>
                {expenses[0].year}
            </div>
            {
                expenses?.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0)).map((expense, index) => {
                    return (
                        <div className='expense-item' key={index} onClick={() => setSelectedExpense(expense)}>
                            <div className='expense-summary'>
                                <h3>{expense.name}</h3>
                                <h4>{dateHandler(expense.date)}</h4>
                            </div>
                            <span className='expense-amount'>
                                {currencyHandler(expense.amount)}
                                <IoIosArrowForward />
                            </span>
                        </div>
                    )
                })
            }
        </section>
    )
}

export {ExpensesList};