import React, { useEffect, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';

const ContributionSchedule = () => {

    const [ activeSchedule, setActiveSchedule ] = useState(false);

    // Schedule Data - Dual Source
    const [ scheduleSource, setScheduleSource ] = useState('individual');
    const [ iAmount, setIAmount ] = useState(0);
    const [ iCadence, setICadence ] = useState('monthly');
    const [ eAmount, setEAmount ] = useState(0);
    const [ eCadence, setECadence ] = useState('monthly');
    // Note: 'yyyy-MM-dd' allows for an unset value and triggers proper rerender between individual/employer values.
    // Add check in save call to handle these empty values.
    const [ iStartDate, setIStartDate ] = useState('yyyy-MM-dd');
    const [ iEndDate, setIEndDate ] = useState('yyyy-MM-dd');
    const [ eStartDate, setEStartDate ] = useState('yyyy-MM-dd');
    const [ eEndDate, setEEndDate ] = useState('yyyy-MM-dd');
    const [ hasEEndDate, setHasEEndDate ] = useState(true);
    const [ hasIEndDate, setHasIEndDate ] = useState(true);

    return (
        <section className='schedule-panel'>
            {
                activeSchedule ?
                <div className='schedule-editor-wrapper'>
                    <div className='editor-panel'>
                        <span className='schedule-panel-header'>
                            <h2>Contribution Schedule</h2>
                            <span className='schedule-action-btns'>
                                <sl-button class='blocks-pill-btn' pill>Save</sl-button>
                                <AiFillPlusCircle onClick={() => setActiveSchedule(false)} />
                            </span>
                        </span>
                        <sl-radio-group class="contribution-source-toggle" value={scheduleSource}>
                            <sl-radio-button class="contribution-source-option" onClick={() => setScheduleSource('individual')} value={'individual'}>Individual</sl-radio-button>
                            <sl-radio-button class="contribution-source-option" onClick={() => setScheduleSource('employer')} value={'employer'}>Employer</sl-radio-button>
                        </sl-radio-group>
                        <div className='schedule-controls'>
                            <small>Amount</small>
                            <span className='schedule-amount-selectors'>
                                <span className='blocks-currency-input'>
                                    <span>$</span>
                                    <input type='number' onClick={(e) => e.target.select()} onChange={(e) => scheduleSource === 'individual' ? setIAmount(e.target.value) : setEAmount(e.target.value)} onBlur={(e) => scheduleSource === 'individual' ? setIAmount(parseFloat(e.target.value).toFixed(2)) : setEAmount(parseFloat(e.target.value).toFixed(2))} value={scheduleSource === 'individual' ? iAmount : eAmount}></input>
                                </span>
                                <sl-select value={scheduleSource === 'individual' ? iCadence : eCadence} filled>
                                    <sl-option onClick={() => scheduleSource === 'individual' ? setICadence('weekly') : setECadence('weekly')} value='weekly'>Weekly</sl-option>
                                    <sl-option onClick={() => scheduleSource === 'individual' ? setICadence('bi-weekly') : setECadence('bi-weekly')} value='bi-weekly'>Bi-Weekly</sl-option>
                                    <sl-option onClick={() => scheduleSource === 'individual' ? setICadence('semi-monthly') : setECadence('semi-monthly')} value='semi-monthly'>Semi-Monthly</sl-option>
                                    <sl-option onClick={() => scheduleSource === 'individual' ? setICadence('monthly') : setECadence('monthly')} value='monthly'>Monthly</sl-option>
                                    <sl-option onClick={() => scheduleSource === 'individual' ? setICadence('quarterly') : setECadence('quarterly')} value='quarterly'>Quarterly</sl-option>
                                    <sl-option onClick={() => scheduleSource === 'individual' ? setICadence('annually') : setECadence('annually')} value='annually'>Annually</sl-option>
                                </sl-select>
                            </span>
                            <small>Time Frame</small>
                            <span className='end-date-toggle-wrapper'>
                                <label>Has End Date</label>
                                <sl-radio-group class="end-date-toggle" value={scheduleSource === 'individual' ? hasIEndDate : hasEEndDate}>
                                    <sl-radio-button onClick={() => scheduleSource === 'individual' ? setHasIEndDate(true) : setHasEEndDate(true)} value={true}>Yes</sl-radio-button>
                                    <sl-radio-button onClick={() => scheduleSource === 'individual' ? setHasIEndDate(false) : setHasEEndDate(false)} value={false}>No</sl-radio-button>
                                </sl-radio-group>
                            </span>
                            <span class="schedule-date-pickers">
                                <label>Starts</label>
                                <input onChange={(e) => scheduleSource === 'individual' ? setIStartDate(e.target.value) : setEStartDate(e.target.value)} value={scheduleSource === 'individual' ? iStartDate : eStartDate} type="date"></input>
                            </span>
                            {
                                hasIEndDate && scheduleSource === 'individual' ?
                                <span class="schedule-date-pickers">
                                    <label>Ends</label>
                                    <input onChange={(e) => scheduleSource === 'individual' ? setIEndDate(e.target.value) : setEEndDate(e.target.value)} value={scheduleSource === 'individual' ? iEndDate : eEndDate} type="date"></input>
                                </span> :
                                null
                            }
                            {
                                hasEEndDate && scheduleSource !== 'individual' ?
                                <span class="schedule-date-pickers">
                                    <label>Ends</label>
                                    <input onChange={(e) => scheduleSource === 'individual' ? setIEndDate(e.target.value) : setEEndDate(e.target.value)} value={scheduleSource === 'individual' ? iEndDate : eEndDate} type="date"></input>
                                </span> :
                                null
                            }
                        </div>
                    </div>
                    <sl-button class="otc-btn" variant="neutral">One-Time Contribution</sl-button>
                </div> :
                <sl-button onClick={() => setActiveSchedule(true)} class="create-schedule-btn">Create Contribution Schedule</sl-button>
            }
        </section>
    )
}

export { ContributionSchedule };