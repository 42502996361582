import React, { useContext, useEffect, useState, useReducer } from "react";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  updateProfile,
  onAuthStateChanged,
} from "firebase/auth";
import {
  doc,
  setDoc,
  onSnapshot,
  collection,
  query,
  where,
  updateDoc,
  getDocs,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { createProCheckoutSession } from "../stripe/checkoutSession";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children, handleColorMode }) {
  const [authLoading, setAuthLoading] = useState(true);
  const [pricing, setPricing] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [activeUserProfile, setActiveUserProfile] = useState();
  const [colorMode, setColorMode] = useState();
  const [expenses, setExpenses] = useState();
  const [hsaExpenseGuide, setHsaExpenseGuide] = useState();
  const [hasGuide, setHasGuide] = useState(false);
  const [loadingExpenses, setLoadingExpenses] = useState(true);
  const [noExpenses, setNoExpenses] = useState(false);

  const [loadingDocs, setLoadingDocs] = useState(true);
  const [legalDocs, setLegalDocs] = useState();

  // Expense Action Loaders
  const [addExpenseLoading, setAddExpenseLoading] = useState(false);
  const [updateExpenseLoading, setUpdateExpenseLoading] = useState(false);
  const [deleteExpenseLoading, setDeleteExpenseLoading] = useState(false);

  const navigate = useNavigate();

  // APP FORMAT - IS PWA INSTALLED
  const standalone =
    navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches;

  // AUTHENTICATION & ACCOUNT CREATION

  const signUp = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password);
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signOut = () => {
    return auth.signOut();
  };

  const authenticate = async (source, email, password, firstName, lastName) => {
    if (source === "firebase") {
      let user = await createUserWithEmailAndPassword(auth, email, password);
      return updateProfile(user.user, {
        displayName: `${firstName} ${lastName}`,
      });
    }
    if (source === "google") {
      const provider = new GoogleAuthProvider();
      return signInWithRedirect(auth, provider);
    }
  };

  const initializeAccount = (plan, uid) => {
    const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
      if (doc) {
        if (!doc?.data()?.uid && !doc?.data()?.firstName) {
          let firstName;
          let lastName;
          if (auth.currentUser.displayName) {
            let fullName = auth.currentUser.displayName.split(" ");
            firstName = fullName[0];
            lastName = fullName[1];
          } else {
            firstName = "";
            lastName = "";
          }
          if (auth.currentUser.providerData[0].providerId === "google.com") {
            createAccount(
              "google",
              plan,
              firstName,
              lastName,
              auth.currentUser.providerData[0].photoURL ??
                auth.currentUser.providerData[0].photoURL
            );
          }
          if (auth.currentUser.providerData[0].providerId === "password") {
            createAccount("firebase", plan, firstName, lastName);
          }
        }
        setActiveUserProfile(doc.data());
        return unsubscribe();
      }
    });
  };

  const createAccount = (source, plan, firstName, lastName, image) => {
    const currentTime = new Date();
    setDoc(doc(db, "users", auth.currentUser.uid), {
      uid: auth.currentUser.uid,
      email: auth.currentUser.email,
      firstName: firstName,
      lastName: lastName,
      picture: image ? image : "",
      created: currentTime,
      subscription: plan,
      subActive: false,
      preferences: [{ colorMode: "light" }],
      provider: source,
    });
  };

  const initiateStarter = async (uid) => {
    await updateDoc(doc(db, "users", uid), {
      subActive: true,
    });
    const userDocSub = onSnapshot(doc(db, "users", uid), (doc) => {
      if (doc) {
        setActiveUserProfile(doc.data());
      }
      if (doc.data().subActive) {
        return userDocSub();
      }
    });
  };

  const setActiveUser = (uid) => {
    const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
      if (doc) {
        setActiveUserProfile(doc.data());
        return unsubscribe();
      }
    });
  };

  const verifyName = (firstName, lastName) => {
    updateDoc(doc(db, "users", auth.currentUser.uid), {
      firstName: firstName,
      lastName: lastName,
    });
  };

  const logTermsAgreement = (uid) => {
    const currentTime = new Date();
    updateDoc(doc(db, "users", uid), {
      currentTermsAgreementDate: currentTime,
    });
  };

  // SUBSCRIPTION HANDLING

  const getPricing = async () => {
    let pricePlans = [];
    const querySnapshot = await getDocs(
      collection(db, "global", "external", "pricing")
    );
    querySnapshot.forEach((doc) => {
      pricePlans.push(doc.data());
    });
    setPricing(pricePlans);
  };

  const openCheckout = (plan, uid) => {
    if (plan !== "shoebox-starter") {
      createProCheckoutSession(uid);
    }
  };

  const verifySubscription = (uid) => {
    const q = query(
      collection(db, "users", uid, "subscriptions"),
      where("status", "==", "active")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot?.docs[0]?.data().status) {
        sessionStorage.setItem(
          "activeSubscription",
          querySnapshot.docs[0].data().status
        );
        updateDoc(doc(db, "users", auth.currentUser.uid), {
          subActive: true,
        });
        const userDocSub = onSnapshot(doc(db, "users", uid), (doc) => {
          if (doc) {
            setActiveUserProfile(doc.data());
          }
          if (doc.data().subActive) {
            userDocSub();
            return unsubscribe();
          }
        });
      }
    });
  };

  // USER SETTINGS & PREFERENCES

  const getLegalDocs = () => {
    const postsUrl =
      "https://public-api.wordpress.com/rest/v1.1/sites/blocksfinance.wordpress.com/posts/?category=shoebox";
    fetch(postsUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        let legalDocs = [];
        data.posts.map((post) => {
          if (
            post.categories.hasOwnProperty("S Terms of Use") ||
            post.categories.hasOwnProperty("S Privacy Policy")
          ) {
            legalDocs.push(post);
          }
        });
        setLegalDocs(legalDocs);
        setLoadingDocs(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingDocs(false);
      });
  };

  const applyPreferences = (uid) => {
    const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
      if (doc) {
        handleColorMode(doc.data().preferences[0].colorMode);
        setColorMode(doc.data().preferences[0].colorMode);
        return unsubscribe();
      }
    });
  };

  const updateColorPreference = (uid, preference) => {
    updateDoc(doc(db, "users", uid), {
      preferences: [{ colorMode: preference }],
    });
    applyPreferences(uid);
  };

  // SHOEBOX EXPENSES

  const addExpense = async (payload, uid) => {
    setAddExpenseLoading(true);
    const docRef = doc(db, "users", uid, "expenses", payload.year);
    await updateDoc(docRef, {
      expenses: arrayUnion(payload),
    })
      .then(() => {
        setAddExpenseLoading(false);
        getExpenses(uid);
        return;
      })
      .catch((error) => {
        console.error(error);
        setAddExpenseLoading(false);
      });
  };

  const updateExpense = async (payload, uid) => {
    setUpdateExpenseLoading(true);
    let newExpenses = expenses[0].expenses.filter((expense) => {
      return expense.id !== payload.id;
    });
    newExpenses.push(payload);
    await setDoc(doc(db, "users", uid, "expenses", payload.year), {
      expenses: newExpenses,
    })
      .then(() => {
        setUpdateExpenseLoading(false);
        getExpenses(uid);
      })
      .catch((error) => {
        console.error(error);
        setUpdateExpenseLoading(false);
      });
  };

  const deleteExpense = async (payload, uid) => {
    setDeleteExpenseLoading(true);
    let newExpenses = expenses[0].expenses.filter((expense) => {
      return expense.id !== payload.id;
    });
    await setDoc(doc(db, "users", uid, "expenses", payload.year), {
      expenses: newExpenses,
    })
      .then(() => {
        setDeleteExpenseLoading(false);
        getExpenses(uid);
      })
      .catch((error) => {
        console.error(error);
        setDeleteExpenseLoading(false);
      });
  };

  const getExpenses = async (uid) => {
    setLoadingExpenses(true);
    let tempExpenses = [];
    const querySnapshot = await getDocs(
      collection(db, "users", uid, "expenses")
    );
    if (querySnapshot.empty) {
      setLoadingExpenses(false);
      return false;
    } else {
      querySnapshot.forEach((doc) => {
        tempExpenses.push(doc.data());
      });
      setExpenses(tempExpenses);
      setLoadingExpenses(false);
      return expenses;
    }
  };

  const getHSAExpenseGuide = async () => {
    let docRef = doc(db, "global", "internal", "resources", "education");
    const educationDocSnap = await getDoc(docRef);
    if (educationDocSnap.exists()) {
      setHasGuide(true);
      let guide = educationDocSnap.data().documents.filter((doc) => {
        return doc.title === "HSA Eligible Expenses Guide";
      });
      setHsaExpenseGuide(guide);
      return hsaExpenseGuide;
    } else {
      setHasGuide(false);
    }
  };

  useEffect(() => {
    getPricing();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setAuthLoading(false);
      if (user) {
        applyPreferences(user.uid);
      }
    });
    return unsubscribe;
  }, [expenses, hsaExpenseGuide]);

  const value = {
    standalone,
    pricing,
    getLegalDocs,
    legalDocs,
    loadingDocs,
    currentUser,
    activeUserProfile,
    initializeAccount,
    authenticate,
    openCheckout,
    verifySubscription,
    initiateStarter,
    verifyName,
    logTermsAgreement,
    setActiveUser,
    signUp,
    createAccount,
    signIn,
    signOut,
    updateColorPreference,
    colorMode,
    getHSAExpenseGuide,
    hsaExpenseGuide,
    hasGuide,
    getExpenses,
    addExpense,
    updateExpense,
    deleteExpense,
    addExpenseLoading,
    updateExpenseLoading,
    deleteExpenseLoading,
    expenses,
    loadingExpenses,
    noExpenses,
  };

  return (
    <AuthContext.Provider value={value}>
      {!authLoading && children}
    </AuthContext.Provider>
  );
}
