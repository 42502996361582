import React, { useEffect, useState } from "react";
import Hero from "../../assets/landing/fold-hero.webp";
import addExpense from "../../assets/landing/add-expense.webp";
import expensesList from "../../assets/landing/expenses-list.webp";
import editConfiguration from "../../assets/landing/edit-configuration.webp";
import portfolioDashboard from "../../assets/landing/portfolio-dashboard.webp";
import builtForWeb from "../../assets/landing/built-for-web.webp";
import HSAChart from "../../assets/landing/HSA_Chart.svg";
import TheBlocks from "../../assets/landing/TheBlocksDark.svg";
import Compound from "../../assets/landing/Compound_Light.svg";
import Balance from "../../assets/landing/Balance_Light.svg";
import Posts from "../../assets/landing/Posts_Light.svg";
import About from "../../assets/landing/About_Light.svg";
import {
  TbArrowNarrowRight,
  TbBallpen,
  TbReceipt,
  TbPigMoney,
  TbHeartRateMonitor,
  TbDevices,
} from "react-icons/tb";
import { LuChevronUp } from "react-icons/lu";
import { Pricing } from "./Pricing";
import { Notification } from "./Notification";
import { Footer } from "../global/Footer";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation } from "react-router-dom";

const Landing = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [isPhone, setIsPhone] = useState(true);
  const [toolHighlight, setToolHighlight] = useState("compound");

  const { pricing } = useAuth();

  const location = useLocation();

  const featuresBlock = document.getElementById("features");
  const statsBlock = document.getElementById("stats");
  const pricingBlock = document.getElementById("pricing");
  const additionalToolsBlock = document.getElementById("additional-tools");
  const notifyBlock = document.getElementById("notify-me");

  useEffect(() => {
    updateWidth();
    if (
      pricing &&
      featuresBlock &&
      statsBlock &&
      pricingBlock &&
      additionalToolsBlock &&
      notifyBlock &&
      location?.hash
    ) {
      switch (location?.hash) {
        case "#features":
          console.log(location.hash, featuresBlock.offsetTop);
          window.scrollTo({
            top: featuresBlock.offsetTop - 100,
            behavior: "smooth",
          });
          break;
        case "#stats":
          window.scrollTo({
            top: statsBlock.offsetTop - 100,
            behavior: "smooth",
          });
          break;
        case "#pricing":
          window.scrollTo({
            top: pricingBlock.offsetTop - 100,
            behavior: "smooth",
          });
          break;
        case "#additional-tools":
          window.scrollTo({
            top: additionalToolsBlock.offsetTop - 100,
            behavior: "smooth",
          });
          break;
        case "#notify-me":
          window.scrollTo({
            top: notifyBlock.offsetTop - 100,
            behavior: "smooth",
          });
          break;
        default:
          return;
      }
    }
  }, [
    featuresBlock,
    statsBlock,
    pricingBlock,
    additionalToolsBlock,
    notifyBlock,
    pricing,
  ]);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 800);
    setIsPhone(window.innerWidth < 500);
    document.documentElement.style.setProperty(
      "--shoebox-page-height",
      window.innerHeight + "px"
    );
  };

  window.addEventListener("resize", updateWidth);

  const RenderToolHighlight = ({ toolHighlight }) => {
    let tool;

    switch (toolHighlight) {
      case "compound":
        tool = {
          name: "Compound",
          description:
            "A simple, well-designed utility to calculate compound growth",
          cta: "Open Compound",
          link: "https://theblocks.finance/compound",
        };
        break;
      case "balance":
        tool = {
          name: "Balance",
          description:
            "A utility that takes the guess-work out of portfolio rebalancing",
          cta: "View Balance",
          link: "https://theblocks.finance/resources/calculators",
        };
        break;
      case "posts":
        tool = {
          name: "Posts & Resources",
          description: "Explore our blog posts and other digital resources",
          cta: "View Posts & Resources",
          link: "https://theblocks.finance/resources",
        };
        break;
      case "about":
        tool = {
          name: "About The Blocks",
          description: "Get to know our story and the vision for our future",
          cta: "Meet The Blocks",
          link: "https://theblocks.finance/about",
        };
        break;
    }

    return (
      <>
        <label>{tool?.name}</label>
        <span>{tool?.description}</span>
        <a href={tool?.link} target="_blank">
          {tool?.cta}
        </a>
      </>
    );
  };

  return (
    <div className="landing">
      <section className="landing-fold">
        <div className="hero-content">
          <img alt="landing hero Shoebox UI on iPhone" src={Hero} />
          <div className="hero-text">
            <h1>Track, Manage, and Maximize Your HSA Contributions.</h1>
            <div>
              <a href="#notify-me">
                <sl-button aria-label="Get In Line">
                  Get In Line <TbArrowNarrowRight />
                </sl-button>
              </a>
              <a href="#features">
                <sl-button aria-label="Explore" variant="neutral">
                  {isPhone ? "Explore" : "Explore Shoebox"}
                </sl-button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="features">
        <div className="feature-tabs-container">
          <sl-tab-group placement="start">
            <sl-tab slot="nav" panel="track-expenses" active>
              {isDesktop ? "Track Expenses" : <TbBallpen />}
            </sl-tab>
            <sl-tab slot="nav" panel="add-receipts">
              {isDesktop ? "Add Receipts" : <TbReceipt />}
            </sl-tab>
            <sl-tab slot="nav" panel="set-investments">
              {isDesktop ? "Set Investments" : <TbPigMoney />}
            </sl-tab>
            <sl-tab slot="nav" panel="monitor-progress">
              {isDesktop ? "Monitor Progress" : <TbHeartRateMonitor />}
            </sl-tab>
            <sl-tab slot="nav" panel="built-for-web">
              {isDesktop ? "Built For Web" : <TbDevices />}
            </sl-tab>

            <sl-tab-panel name="track-expenses" active>
              {isDesktop ? (
                <>
                  <img
                    alt="expense management UI on iPhone"
                    src={expensesList}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbBallpen /> : null}
                    <h2>Track Expenses</h2>
                    <div>
                      View all expenses in one place. Take your tracking up a
                      notch from a cluttered box of invoices and receipts to a
                      simple & clean list.
                    </div>
                  </div>
                </>
              ) : (
                <div className="panel-content-wrapper">
                  <img
                    alt="expense management UI on iPhone"
                    src={expensesList}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbBallpen /> : null}
                    <h2>Track Expenses</h2>
                    <div>
                      View all expenses in one place. Take your tracking up a
                      notch from a cluttered box of invoices and receipts to a
                      simple & clean list.
                    </div>
                  </div>
                </div>
              )}
            </sl-tab-panel>
            <sl-tab-panel name="add-receipts">
              {isDesktop ? (
                <>
                  <img alt="expense form UI on iPhone" src={addExpense} />
                  <div className="feature-content">
                    {isDesktop ? <TbReceipt /> : null}
                    <h2>Add Receipts</h2>
                    <div>
                      Quick inputs for the essential fields make sure your
                      expenses are categorized and annotated with any important
                      notes.
                      <br />
                      <br />
                      Snap a picture of your receipt to complete the process.
                    </div>
                  </div>
                </>
              ) : (
                <div className="panel-content-wrapper">
                  <img alt="expense form UI on iPhone" src={addExpense} />
                  <div className="feature-content">
                    {isDesktop ? <TbReceipt /> : null}
                    <h2>Add Receipts</h2>
                    <div>
                      Quick inputs for the essential fields make sure your
                      expenses are categorized and annotated with any important
                      notes.
                      <br />
                      <br />
                      Snap a picture of your receipt to complete the process.
                    </div>
                  </div>
                </div>
              )}
            </sl-tab-panel>
            <sl-tab-panel name="set-investments">
              {isDesktop ? (
                <>
                  <img
                    alt="portfolio dashboard UI on iPhone"
                    src={portfolioDashboard}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbPigMoney /> : null}
                    <h2>Set Investments</h2>
                    <div>
                      With your Shoebox portfolio, track your total HSA balance,
                      annual progress for maxing contributions, and view an
                      expense:portfolio ratio to monitor how you're trending.
                      <br />
                      <br />A health check ensures that you're maximizing all
                      the benefits of your HSA.
                    </div>
                  </div>
                </>
              ) : (
                <div className="panel-content-wrapper">
                  <img
                    alt="portfolio dashboard UI on iPhone"
                    src={portfolioDashboard}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbPigMoney /> : null}
                    <h2>Set Investments</h2>
                    <div>
                      With your Shoebox portfolio, track your total HSA balance,
                      annual progress for maxing contributions, and view an
                      expense:portfolio ratio to monitor how you're trending.
                      <br />
                      <br />A health check ensures that you're maximizing all
                      the benefits of your HSA.
                    </div>
                  </div>
                </div>
              )}
            </sl-tab-panel>
            <sl-tab-panel name="monitor-progress">
              {isDesktop ? (
                <>
                  <img
                    alt="configuration panel UI on iPhone"
                    src={editConfiguration}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbHeartRateMonitor /> : null}
                    <h2>Monitor Progress</h2>
                    <div>
                      With advanced portfolio configuration, you're in control
                      to set your expected annual contributions.
                      <br />
                      <br />
                      Through a projected annual withdrawal rate and an annual
                      return tracked by popular indices, we can estimate how
                      your total portfolio will change over time.
                    </div>
                  </div>
                </>
              ) : (
                <div className="panel-content-wrapper">
                  <img
                    alt="configuration panel UI on iPhone"
                    src={editConfiguration}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbHeartRateMonitor /> : null}
                    <h2>Monitor Progress</h2>
                    <div>
                      With advanced portfolio configuration, you're in control
                      to set your expected annual contributions.
                      <br />
                      <br />
                      Through a projected annual withdrawal rate and an annual
                      return tracked by popular indices, we can estimate how
                      your total portfolio will change over time.
                    </div>
                  </div>
                </div>
              )}
            </sl-tab-panel>
            <sl-tab-panel name="built-for-web">
              {isDesktop ? (
                <>
                  <img
                    alt="Shoebox app on iPhone and iPad"
                    className="web-img"
                    src={builtForWeb}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbDevices /> : null}
                    <h2>Built For Web</h2>
                    <div>
                      Available for modern browsers on any computer, tablet, or
                      phone — Shoebox is ready to record your expenses in
                      whatever environment is most convenient.
                      <br />
                      <br />
                      Easily view and monitor your HSA growth from the couch, or
                      on the go.
                    </div>
                  </div>
                </>
              ) : (
                <div className="panel-content-wrapper">
                  <img
                    alt="Shoebox app on iPhone and iPad"
                    className="web-img"
                    src={builtForWeb}
                  />
                  <div className="feature-content">
                    {isDesktop ? <TbDevices /> : null}
                    <h2>Built For Web</h2>
                    <div>
                      Available for modern browsers on any computer, tablet, or
                      phone — Shoebox is ready to record your expenses in
                      whatever environment is most convenient.
                      <br />
                      <br />
                      Easily view and monitor your HSA growth from the couch, or
                      on the go.
                    </div>
                  </div>
                </div>
              )}
            </sl-tab-panel>
          </sl-tab-group>
        </div>
      </section>
      <section id="stats">
        <div className="statVisuals">
          <div className="statCopy">
            <h2>
              The <span>Average</span> HSA
            </h2>
            <span>vs</span>
            <h2>
              The <span>Shoebox</span> HSA
            </h2>
          </div>
          <div>
            <img
              src={HSAChart}
              alt="Chart graphic showing illustrated growth of two portfolios"
            />
          </div>
        </div>
        <div className="statFigures">
          <div className="barChart">
            <span>
              Average Annual Growth of a 'Standard' HSA<sup>$</sup>
            </span>
            <div className="bar standard-bar">
              <div className="inner-bar innerColor">$170</div>
            </div>
          </div>
          <div className="barChart">
            <span>
              Average Annual Growth of a 'Shoeboxed' HSA<sup>$</sup>
            </span>
            <div className="bar shoeboxed-bar">
              <div className="inner-bar innerColor">$3,420</div>
            </div>
          </div>
          <div className="statSource">
            <sup>$</sup>
            <a
              href="https://www.cnbc.com/2021/10/15/91percent-of-people-with-health-savings-accounts-make-this-mistake.html"
              target="_blank"
            >
              Source: CNBC October 2021
            </a>
            , average HSA growth of an invested account in 2020 was $3,420
            whereas an HSA account with cash-only holdings only grew by $170.
          </div>
        </div>
      </section>
      <section id="pricing">
        {isDesktop ? (
          <h2>
            Simple Pricing. <span>Free to Get Started.</span>
          </h2>
        ) : (
          <h2>
            Simple Pricing.
            <br />
            <span>Free to Get Started.</span>
          </h2>
        )}
        <Pricing />
      </section>
      <section id="notify-me">
        <Notification />
      </section>
      <section id="additional-tools">
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient
              id="a"
              gradientUnits="objectBoundingBox"
              x1="0"
              y1="0"
              x2="1"
              y2="1"
            >
              <stop offset="0" stop-color="#9cc9ff">
                <animate
                  attributeName="stop-color"
                  values="#9cc9ff;white;#cae1fe;white;#cae1fe;#9cc9ff;#9cc9ff;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset=".5" stop-color="#cae1fe">
                <animate
                  attributeName="stop-color"
                  values="#cae1fe;white;#cae1fe;#9cc9ff;#cae1fe;white;#cae1fe;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset="1" stop-color="#9cc9ff">
                <animate
                  attributeName="stop-color"
                  values="white;#cae1fe;white;#cae1fe;white;#cae1fe;white;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <animateTransform
                attributeName="gradientTransform"
                type="rotate"
                from="0 .5 .5"
                to="360 .5 .5"
                dur="20s"
                repeatCount="indefinite"
              />
            </linearGradient>
            <linearGradient
              id="b"
              gradientUnits="objectBoundingBox"
              x1="0"
              y1="1"
              x2="1"
              y2="1"
            >
              <stop offset="0" stop-color="#9cc9ff">
                <animate
                  attributeName="stop-color"
                  values="#9cc9ff;white;#cae1fe;white;#cae1fe;#9cc9ff;#9cc9ff;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset="1" stop-color="#cae1fe" stop-opacity="0">
                <animate
                  attributeName="stop-color"
                  values="#cae1fe;white;#cae1fe;#9cc9ff;#cae1fe;white;#cae1fe;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <animateTransform
                attributeName="gradientTransform"
                type="rotate"
                values="360 .5 .5;0 .5 .5"
                class="ignore"
                dur="10s"
                repeatCount="indefinite"
              />
            </linearGradient>
          </defs>
          <rect fill="url(#a)" width="100%" height="100%" />
          <rect fill="url(#b)" width="100%" height="100%" />
        </svg>
        <div className="additional-tools-content">
          <div className="section-one">
            <h2>
              Additional Tools For
              <br />
              Your Financial Life
            </h2>
            <div className="app-section">
              <img
                alt="Compound App Icon"
                src={Compound}
                className={toolHighlight === "compound" ? "active" : ""}
                onClick={() => setToolHighlight("compound")}
              />
              <img
                alt="Balance App Icon"
                src={Balance}
                className={toolHighlight === "balance" ? "active" : ""}
                onClick={() => setToolHighlight("balance")}
              />
              <img
                alt="Blog Posts Icon"
                src={Posts}
                className={toolHighlight === "posts" ? "active" : ""}
                onClick={() => setToolHighlight("posts")}
              />
              <img
                alt="The Blocks App Icon"
                src={About}
                className={toolHighlight === "about" ? "active" : ""}
                onClick={() => setToolHighlight("about")}
              />
              <LuChevronUp
                className="selector-arrow"
                style={
                  toolHighlight !== "compound"
                    ? toolHighlight !== "balance"
                      ? toolHighlight !== "posts"
                        ? { left: "86.375%" }
                        : { left: "59.375%" }
                      : { left: "32.375%" }
                    : { left: "5.375%" }
                }
              />
            </div>
            <div className="description-box">
              <RenderToolHighlight toolHighlight={toolHighlight} />
            </div>
          </div>
          <div className="section-two">
            <img alt="The Blocks App Icon" src={TheBlocks} />
            <h3>
              Designed & Developed
              <br />
              by The Blocks
            </h3>
            <span>
              The Blocks helps individuals build a solid foundation for their
              financial future. We build well-designed financial apps and
              provide educational resources to help you along the way.
              <br />
              <br />
              Shoebox is one of the many tools on the path to achieve this goal.
              Visit{" "}
              <a href="https://theblocks.finance" target="_blank">
                The Blocks
              </a>{" "}
              to find even more ways you can get started.
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export { Landing };
