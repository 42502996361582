import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tiles } from './tiles/Tiles';
import dashboard from '../../assets/icons/dashboard.svg';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { ContributionSchedule } from './ContributionSchedule';
import { Investments } from './Investments';

const Portfolio = () => {

  const [ contributionSchedule, setContributionSchedule ] = useState();
  const [ investments, setInvestments ] = useState();

  let configDrawer;
  let openButton;
  let closeButton;

  useEffect(() => {
    configDrawer = document.getElementById('configuration-popup');
    openButton = document.getElementById('open-configuration-btn');
    closeButton = document.getElementById('close-configuration-btn');
    openButton?.addEventListener('click', () => configDrawer.show());
    closeButton?.addEventListener('click', () => {
      configDrawer.hide();
    });

    // Prevent the dialog from closing when the user clicks on the overlay
    configDrawer?.addEventListener('sl-request-close', event => {
      if (event.detail.source === 'overlay') {
          event.preventDefault();
      }
    });
  }, [openButton, closeButton, configDrawer])

  const NoConfiguration = () => {
    return (
      <div className='unconfigured-portfolio'>
        <img src={dashboard} className='dashboard-icon new-folio-header' />
        <h2>Shiny New Portfolio Alert!</h2>
        <span>Let's Change That:</span>
        <sl-button onClick={() => configDrawer.show()} id='initial-config-btn' class='blocks-pill-btn' pill>Configure Your Portfolio</sl-button>
      </div>
    )
  }

  return (
    <>
      <section className='portfolio-header'>
        <h1>Portfolio</h1>
        <img src={dashboard} className='dashboard-icon' id='open-configuration-btn' />
      </section>
      {
        (!contributionSchedule || !investments) ?
        <NoConfiguration /> :
        <Tiles />
      }
      <sl-drawer placement="bottom" class='shoebox-drawer' id="configuration-popup">
          <span slot='label' class='drawer-header'><h1>Configuration</h1><AiFillPlusCircle id='close-configuration-btn' /></span>
          <section className='account-overview'>
            <span>
              <small>Current Account Value</small>
              <small className='edit-account-value'>Edit</small>
            </span>
            <div className='account-value-window'>$0</div>
          </section>
          <section className='contributions-configuration'>
            <small>Contributions</small>
            <ContributionSchedule />
          </section>
          <section className='investments-configuration'>
            <small>Investments</small>
            <Investments />
          </section>
          <sl-button slot="footer" class='shoebox-drawer-action-button' variant="neutral" id="close-configuration-btn">All Done</sl-button>
      </sl-drawer>
    </>
  )
}

export { Portfolio };