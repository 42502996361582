import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PublicRoute = () => {
    const { currentUser } = useAuth();
    return !currentUser ? <Outlet /> : <Navigate to='/expenses' />;
}

const PrivateRoute = () => {
    const { currentUser } = useAuth();
    return currentUser ? <Outlet /> : <Navigate to='/' />;
}

export { PublicRoute, PrivateRoute };